import React from 'react'
import FontPicker from "font-picker-react";
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';

const FontsInputStyles = theme => ({
FontPickerContainer : {
  background: "#0f0",
  "& > .apply-font" : {
    background: "#f00",
  }
}
});

class FontsInput extends React.Component {
 constructor(props) {
    super(props);
    this.state = {
          activeFontFamily: this.props.input.value || "Open Sans",
    };
  }

  render() {
     return (
      <div className="FontPickerContainer">
        <Tooltip title="Pick a Font to be applied to Your Videos">
        <div>
        <FontPicker
          apiKey="AIzaSyA52DOZWBke6rm4i0f2inFMjWho0JKY3Ug"
          activeFontFamily={this.state.activeFontFamily}
          onChange={nextFont => {
              this.setState({
                activeFontFamily: nextFont.family,
              })
              this.props.input.onChange(this.state.activeFontFamily);
            }
          }
        />
        <p className="apply-font">The font will be applied to Your Videos.</p>
        </div>
        </Tooltip>
      </div>
    )
  }
}

export default  withStyles(FontsInputStyles)(FontsInput)