import React, {Component} from 'react';
import {SelectInput} from 'react-admin';
import AudioPlayer from 'material-ui-audio-player';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: '10px'
    }
}))

export default class AudioPlayerObject extends Component {

    constructor(props) {
        super(props);

        if (typeof this.props.form.getState().initialValues.music !== 'undefined' &&
            this.props.form.getState().initialValues.music !== null) {
            this.state = {
                name: this.props.form.getState().initialValues.music,
            };
        } else {
            this.state = {
                name: null,
            };
        }
    }

    onNameChange(event) {
        if(event && event.target && event.target.value) {
            this.setState({name: event.target.value});
        }else{
            this.setState({name: null});
        }

    }

    render() {
        return (
            <>
                <SelectInput  resettable={true} source="music" choices={[
                    { id: 'adventures-a-himitsu-no-copyright-music', name: 'Adventures - A himitsu' },
                    { id: 'eagle-rock-wes-hutchinson-royalty-free-music-no-copyright-music', name: 'Wes Hutchinson - Eagle rock' },
                    { id: 'we-are-one-vexento-vlog-no-copyright-music', name: 'Vexento - We are one' },
                ]} onChange={(e) => this.onNameChange(e)} />
                {this.state.name ? <AudioPlayer
                    useStyles={useStyles}
                    src={ process.env.REACT_APP_VIDEO_STREAM_URL_BASE+'/ambiences/'+this.state.name+'.mp3' }
                    spacing={-1}
                /> : null}
            </>)
    }
}
