import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import ExitToApp from '@material-ui/icons/ExitToApp';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import NavLinkMui from '../../components/Navbars/NavLinkMui';
import SiteIcon from '@material-ui/icons/Web';
import VideoIcon from '@material-ui/icons/Theaters';
import auth0Client from './Auth';

const styles = theme => ({
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
    inline : {
      display : 'inline',
    },
    userMenu: {
        border : "none",
        padding : "0",
        "box-shadow" : "none",
        margin : "0px 0 0",
        "buttons" : {
          display : "block"
        }
    },

});

class LogoutButton extends Component {

    render() {
      const { Icon, classes,parentClass } = this.props;
      const user = auth0Client.getProfile();
      return ((user) ? (

      <List className={classes.userMenu}>
          <ListItem>
            <ListItemAvatar>
              {(!user.picture) ? <Avatar>{user.name[0]}</Avatar> :
                                 <Avatar className={classes.avatar} src={user.picture}></Avatar>}
            </ListItemAvatar>
            <ListItemText
              primary={"Hello "+user.name}
              secondary={
                <React.Fragment>
                  <Typography
                    component="span"
                    variant="caption"
                    className={classes.inline}
                    color="textPrimary"
                  >
                    {(user.company)? user.company.company_name : ''}
                  </Typography> - {user.email}
                   <br/> {user.roles.join(' ')}
                </React.Fragment>
              }
            />
          </ListItem>

          <Divider />
          <ListItem button component={NavLinkMui} to='/sites'>
            <ListItemIcon><SiteIcon/></ListItemIcon>
            <ListItemText primary="Manage Your Sites" />
          </ListItem>
          <ListItem button component={NavLinkMui} to='/pages'>
            <ListItemIcon><VideoIcon/></ListItemIcon>
            <ListItemText primary="Manage Your Pages" />
          </ListItem>

          <ListItem button onClick={(e)=> {console.log("HERE");auth0Client.signOut({redirect:true});}}>
              <ListItemIcon><Icon className={[classes.extendedIcon,parentClass].join(' ')} /></ListItemIcon>
              <ListItemText primary="Logout" />
          </ListItem>
      </List>
            ) : (
            <List>
             <ListItem button onClick={(e)=> {console.log("HERE2");auth0Client.signOut({redirect:true});}}>
              <ListItemIcon><Icon className={[classes.extendedIcon,parentClass].join(' ')} /></ListItemIcon>
              <ListItemText primary="Logout" />
          </ListItem>
          </List>
      ))
    }
}

LogoutButton.defaultProps = {
    label: 'Logout',
    Icon: ExitToApp
};

export default  withStyles(styles)(LogoutButton);