
import React, {Fragment} from 'react';
import {
    List,
    Datagrid,
    TextField,
    EditButton,
    ShowButton,
    SaveButton,
    DeleteButton,
    Edit,
    Create,
    SimpleForm,
    TextInput,
    Filter,
    UrlField,
    DateField,
    ArrayField,
    ArrayInput,
    TabbedForm,
    Toolbar,
    FormTab,
    Show,
    SimpleShowLayout
}
from 'react-admin';
import { Field } from 'react-final-form';
import Button from '@material-ui/core/Button';
import AutoRenewIcon from '@material-ui/icons/Autorenew';
import RichTextInput from 'ra-input-rich-text';
import withStyles from '@material-ui/core/styles/withStyles';
import SanetizedGrid from '../components/Grid/SanetizedGrid';
import Sequence from './Sequence';
import OrderedFormIterator from '../components/Draggable/OrderedFormIterator';
import ImageLibraryModale from '../components/Modals/ImageLibraryModale';
import VideoLibraryModale from '../components/Modals/VideoLibraryModale';
import VideoSettings from '../components/Sidebar/VideoSettings';
import { useForm } from 'react-final-form';
import PageStatus from './PageStatus';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import auth0Client from '../components/Auth/Auth';
import Utils from '../lib/utils'


const PagesFilter = (props) => (
    <Filter {...props}>
        <TextInput variant="outlined" label="Search" source="title" alwaysOn />
        <TextInput variant="outlined" label="Domain" source="domain" />
        <TextInput variant="outlined" label="URL" source="url" />
        <TextInput variant="outlined" source="updated_by" />
        <TextInput variant="outlined" source="company"  />
    </Filter>
);

const EditButtonWithStatus = props => {

    const id = props.record.id;
      const disabled= (window.PageStatus && window.PageStatus[id])? (window.PageStatus[id]=== 'progress') : false;
      // console.log("my id", id, "disabled", disabled);

    return (
    <Fragment>
      <ShowButton disabled={disabled} {...props}/>
      <EditButton disabled={disabled} {...props} />
    </Fragment>
    );
  }

export const PageList = props => {
    return (
        <List {...props} filters={<PagesFilter/>} sort={{ field: 'updatedAt', order: 'DESC' }}>
            <Datagrid>

                <PageStatus {...props} />
                <TextField source="title"  label="Page Title"/>
                <TextField source="domain"/>
                <UrlField  source="url"/>
                <DateField source="createdAt" label="Created at"/>
                <DateField source="updatedAt" label="Updated at" showTime/>
                <TextField source="updated_by" />
                <TextField source="company"  />
                <EditButtonWithStatus />
                <DeleteButton/>
            </Datagrid>
        </List>
    );

}


const PageEditStyles = theme => ({
    FormTabSequences: {

    },
    formContainer:{
      width: "100%",
      display: "flex",
    },
    formArrayInput: {
      "flex": "1 1 auto",
    },
    VideoSettings: {
      "flex": "1 1 auto",
    },
    AdvancedSettings : {
      width : "100%",
    }
});

/* Create an Intermediate component to share the data to it */
const MyImageLibraryModale = (props) => {
  return (<Field label="Image" name={props.source} source="image" key={props.data.id+'_'+props.index} data={props.data} index={props.index} item ={props.item} component={ImageLibraryModale} extraction={props.extraction}/>);
};

const MyVideoLibraryModale = (props) => {
    return (<Field name={props.source} source="video" component={VideoLibraryModale} data={props.data} item={props.item} extraction={props.extraction} />);
};


const MediaContainer = (props) => {
  const form = useForm();
  const extraction = (form) ? form.getState().values.extraction : {};
  let {source, ...rest} = props;
  let sources = source.split('.');
  sources[2] = "image";
  source = sources.join('.');

  let sourceVideo = source.split('.');
  sourceVideo[2] = "video";
  sourceVideo = sourceVideo.join('.');
  return (
    <SanetizedGrid container spacing={1} className="row" >
      <SanetizedGrid item sm={(props.item.video) ? 6 : 6}>
        <MyImageLibraryModale source={source} extraction={extraction} {...rest} />
      </SanetizedGrid>
      {(props.item.video || true) ?   (
      <SanetizedGrid item sm={6}>
        <MyVideoLibraryModale {...rest} source={sourceVideo} extraction={extraction} />
      </SanetizedGrid>) : (null) }
    </SanetizedGrid>
  );
};


const PageEditActions = (props) =>  {

  return (
     <Toolbar {...props}>
          <SaveButton
              label="Save"
              redirect={false}
              className="myEditSaveButton"
              {...props}
          />
          <Button variant="fill" color="primary" onClick={(e) => { document.querySelector(".btnGenerate").click();}}>
            <AutoRenewIcon/> Generate
          </Button>
          <DeleteButton {...props}/>
      </Toolbar>
  )
}



const MyVideoSettings = (props) => {
    const form = useForm();
    const extraction = (form) ? form.getState().values.extraction : {};
    return (<Field name="video" source="video" component={VideoSettings} {...props} form={form} extraction={extraction} />);
}


const AdvancedSettings = (props) => {
  const { item } = props;

  let credit=item.member+'.credits';
  let custom_duration=item.member+'.custom_duration';
return(
  <ExpansionPanel>
  <ExpansionPanelSummary
    expandIcon={<ExpandMoreIcon />}
  >
    <Typography>Advanced</Typography>
  </ExpansionPanelSummary>
  <ExpansionPanelDetails>
    <TextInput variant="outlined" source={custom_duration} helperText="You can define a Custom Duration for this sequence in the Video in Seconds" label="Custom Sequence Duration" type="number" />
    <TextInput variant="outlined" source={credit} helperText="You can define a credit" label="Credit" type="text" />
  </ExpansionPanelDetails>
</ExpansionPanel>
);
}



export const PageEdit =  withStyles(PageEditStyles)(({ classes, ...props }) => {
  const {basebath, ...rest} = props;
    return (
    <Edit className={classes.EditSection}  {...props} undoable={false}>
        <TabbedForm toolbar={<PageEditActions {...rest} />} >
            <FormTab label="Sequences">
              <SanetizedGrid {...props} container spacing={1} className="row" >
                <SanetizedGrid {...props} item xs={12} >
                  <ArrayInput {...props}
                      label='Edit and Order the video Sequences'
                      source="extraction.sequences"
                      className={classes.formArrayInput}
                  >
                    <OrderedFormIterator data={props}>
                      <TextInput variant="outlined" fullWidth source="title" />
                      <MediaContainer />
                      <RichTextInput variant="outlined" source="highlighted_text" />
                      <AdvancedSettings />
                    </OrderedFormIterator>
                  </ArrayInput>
                </SanetizedGrid>
                <SanetizedGrid item xs={12} {...props}>
                  <MyVideoSettings idPage={props.id}/>
                </SanetizedGrid>
              </SanetizedGrid>
            </FormTab>
            <FormTab label="Meta">
                <TextInput variant="outlined" disabled source="id" />
                <TextInput variant="outlined" disabled source="domain" />
                <TextInput variant="outlined" disabled source="url" />
                <TextInput variant="outlined" disabled source="updated_by" value={auth0Client.getProfile().email} />
                <TextInput variant="outlined" disabled source="user_id" value={auth0Client.getProfile().sub} />
                <TextInput variant="outlined" disabled source="updatedAt" />
                <TextInput variant="outlined" disabled source="company" parse={Utils.getCompany} format={Utils.getCompany} />

            </FormTab>
        </TabbedForm>
    </Edit>
)
});



export const PageCreate = props => {
  const postDefaultValue = {status: 'progress'};
    return (
      <Create  {...props}>
        <SimpleForm redirect="list" defaultValue={postDefaultValue}>
          <TextInput variant="outlined" source="url"/>
        </SimpleForm>
      </Create>
  )
};


export const PageShow = props => (

    <Show {...props}>
        <SimpleShowLayout>
            <ArrayField source="extraction.sequences" label="sequences">
                    <Sequence  props={props}/>
            </ArrayField>
        </SimpleShowLayout>
    </Show>
);


