import React, { Component } from 'react';
import {Responsive, withDataProvider } from 'react-admin';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import ChartistGraph from "react-chartist";
import withStyles from "@material-ui/core/styles/withStyles";
import Welcome from './Welcome';
import Metrics from './Metrics';
import DataTable from './DataTable';
import Card from "../components/Card/Card.jsx";
import CardHeader from "../components/Card/CardHeader.jsx";
import CardBody from "../components/Card/CardBody.jsx";
import CardFooter from "../components/Card/CardFooter.jsx";
import AccessTime from "@material-ui/icons/AccessTime";
import Button from '@material-ui/core/Button';
import PropTypes from "prop-types";
import TextField from '@material-ui/core/TextField';
import "../assets/css/material-dashboard-react.css?v=1.6.0";
import analyticsData from "../lib/analyticsData.js"
import {
    dailySalesChart,
    emailsSubscriptionChart,
    completedTasksChart
} from "../variables/charts.jsx";
import dashboardStyle from "../assets/jss/material-dashboard-react/views/dashboardStyle.jsx";
const styles = {
    flex: { display: 'flex' },
    flexColumn: { display: 'flex', flexDirection: 'column' },
    leftCol: { flex: 1, marginRight: '1em' },
    rightCol: { flex: 1, marginLeft: '1em' },
    singleCol: { marginTop: '2em', marginBottom: '2em' },
    startDate: {display: 'flex',flexWrap: 'wrap',marginRight:'3em',marginLeft:'3em'},
    endDate: {display: 'flex',flexWrap: 'wrap',marginRight:'3em',marginLeft:'3em'},
    textField: {width: 200,},
};


class Dashboard extends Component {

    constructor(props){
        super(props);
        this.state = {
            error: null,
            data:null,
            table:null,
            graph1:{},
            graph2:{},
            graph3:{},
            startDate:'2020-07-01',
            endDate:'2020-08-01',
            metrics:[{name:"totalEvents"} ,{name:"eventValue"},{name:"uniqueEvents"}]
        };
          // console.log("Dashboard props" , props);
    }
    componentDidMount() {
        this.init();
    }

    componentDidUpdate(prevProps) {
        // handle refresh
        if (this.props.version !== prevProps.version) {
            this.init();
        }
    }

    
    async init() {
        //graph datas
        let graph1Actions = ["ad_impression","Player Load"]
        let graph2Actions = ["Media Play","Media Pause"]
        let graph3Actions = ["ad_start","ad_complete"]
        //fetch data
        let dataAnalytics = new analyticsData("google")
        await dataAnalytics.init(this.state.startDate,this.state.endDate)
        //data in state
        this.setState({
            data : dataAnalytics,
            graph1 : dataAnalytics.getGraph(graph1Actions,this.state.startDate,this.state.endDate),
            graph2 : dataAnalytics.getGraph(graph2Actions,this.state.startDate,this.state.endDate),
            graph3 : dataAnalytics.getGraph(graph3Actions,this.state.startDate,this.state.endDate),
            table : dataAnalytics.getTableData(),
            metrics : dataAnalytics.getMetrics()
        })
        console.log("data fetched", this.state.data)
    }

    render() {
        let { metrics } = this.state;
        const { classes } = this.props;
        return (
            <Responsive
                xsmall={
                    <div>
                        <div style={styles.flexColumn}>
                            <div style={{ marginBottom: '2em' }}>
                            {(this.state.table !== null) ? (<DataTable data={this.state.table}/>) : (null) }
                            </div>
                            <div style={styles.flex}>
                                <Metrics metric={metrics[0]} />
                            </div>
                        </div>
                    </div>
                }
                small={
                    <div style={styles.flexColumn}>
                        <div style={styles.singleCol}>
                            <Welcome />
                        </div>
                        <div style={styles.flex}>
                            <Metrics metric={metrics[0]} />
                        </div>
                    </div>
                }
                medium={
                    <div style={styles.flex}>
                        <div style={styles.leftCol}>
                            <div style={styles.flex}>
                            {(metrics[0] !== null) ? (<Metrics metric={metrics[0]} />) : (null) }
                            {(metrics[1] !== null) ? (<Metrics metric={metrics[1]} />) : (null) }
                            {(metrics[2] !== null) ? (<Metrics metric={metrics[2]} />) : (null) }
                                </div>
                            <div style={styles.singleCol}>
                            {(this.state.table !== null) ? (<DataTable data={this.state.table}/>) : (null) }
                            </div>
                        </div>
                        <div style={styles.rightCol}>
                            <div style={styles.flex}>
                                <form className={styles.startDate} noValidate>
                                        <TextField
                                            id="startDateInput"
                                            label="Start date"
                                            type="date"
                                            value={this.state.startDate}
                                            onChange={e => this.setState({ startDate: e.target.value },() =>{
                                                this.init()
                                            })}
                                            InputLabelProps={{
                                            shrink: true,
                                            }}
                                        />
                                </form>

                                <form className={styles.endDate} noValidate>
                                        <TextField
                                            id="startDateInput"
                                            label="End date"
                                            type="date"
                                            value={this.state.endDate}
                                            onChange={e => this.setState({ endDate: e.target.value },() =>{
                                                this.init()
                                            })}
                                            InputLabelProps={{
                                            shrink: true,
                                            }}
                                        />
                                    </form>
                                    <Button variant="outlined" color="primary"  onClick={() => {this.setState({
                                        startDate:'2020-07-01',
                                        endDate:'2020-08-01'    
                                        },() =>{
                                            this.init()
                                        })}}>
                                    Last month
                                    </Button>
                                    <Button variant="outlined" color="secondary"  onClick={() => {this.setState({
                                        startDate:'2020-07-23',
                                        endDate:'2020-08-01'    
                                        },() =>{
                                            this.init()
                                        })}}>
                                    Last week
                                    </Button>
                                </div>
                            <div style={styles.flex}>
                                <Card chart>
                                    <CardHeader color="success">
                                    <ChartistGraph
                                            className="ct-chart"
                                            data={this.state.graph1}
                                            type="Line"
                                            options={dailySalesChart.options}
                                            listener={dailySalesChart.animation}
                                        />
                                    </CardHeader>
                                    <CardBody>
                                        <h4 className={classes.cardTitle}>Videos Data</h4>
                                    </CardBody>
                                    <CardFooter chart>
                                        <div className={classes.stats}>
                                            <AccessTime /> Updated today
                                        </div>
                                    </CardFooter>
                                </Card>
                            </div>
                            <div style={styles.flex}>
                                <Card chart>
                                    <CardHeader color="success">
                                        <ChartistGraph
                                            className="ct-chart"
                                            data={this.state.graph2}
                                            type="Line"
                                            options={emailsSubscriptionChart.options}
                                            responsiveOptions={emailsSubscriptionChart.responsiveOptions}
                                            listener={emailsSubscriptionChart.animation}
                                        />
                                    </CardHeader>
                                    <CardBody>
                                        <h4 className={classes.cardTitle}>Users Behavior</h4>
                                    </CardBody>
                                    <CardFooter chart>
                                        <div className={classes.stats}>
                                            <AccessTime /> Updated today
                                        </div>
                                    </CardFooter>
                                </Card>
                            </div>
                            <div style={styles.flex}>
                                <Card chart>
                                    <CardHeader color="success">
                                        <ChartistGraph
                                            className="ct-chart"
                                            data={this.state.graph3}
                                            type="Line"
                                            options={completedTasksChart.options}
                                            listener={completedTasksChart.animation}
                                        />
                                    </CardHeader>
                                    <CardBody>
                                        <h4 className={classes.cardTitle}>Ads Behavior</h4>
                                    </CardBody>
                                    <CardFooter chart>
                                        <div className={classes.stats}>
                                            <AccessTime /> Updated today
                                        </div>
                                    </CardFooter>
                                </Card>
                            </div>
                        </div>
                    </div>
                }
            />
        );
    }
}

Dashboard.propTypes = {
    classes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    version: state.admin.ui.viewVersion,
});

export default compose(
    connect(mapStateToProps),
    withStyles(dashboardStyle),
    withDataProvider
)(Dashboard);
