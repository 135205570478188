import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import CardMedia from '@material-ui/core/CardMedia';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import ListSubheader from '@material-ui/core/ListSubheader';
import InfoIcon from '@material-ui/icons/Info';
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary';
import DeleteIcon from '@material-ui/icons/DeleteForever';
import Tooltip from '@material-ui/core/Tooltip';
import SearchVideoBar from './SearchVideoBar';
import {
  FileField,
  FileInput,
} from 'react-admin';

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    /*width: 500,
    height: 450,*/
  },
  media : {
    "object-fit": "cover",
    maxHeight: "279px",
    minHeight: "279px",
    width : "100%",
    margin : "15px auto"
  },

  btnMedia:{
    margin:"auto 5px",
    fontSize:"75%",
    float:'right',
    textAlign: "center",
  },
  mediaVideo : {
    "object-fit": "contain",
    maxHeight: "279px",
    width : "100%",
    minHeight : "279px",
    margin : "15px auto"
  },
  gridItem: {
    cursor: 'pointer',
    '&:hover': {
      'filter': 'brightness(120%)'
    }
  },
  icon: {
    color: 'rgba(255, 255, 255, 0.54)',
  },
});


const DialogTitle = withStyles(theme => ({
  root: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}))(props => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography variant="body2">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    borderTop: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

class VideoLibraryModale extends React.Component {

  constructor(props) {
      super(props);

      let local_videos = [];
      let sequences_videos = (props.extraction && props.extraction.sequences) ? props.extraction.sequences : [];
      for (var i = sequences_videos.length - 1; i >= 0; i--) {
          if(props.extraction.sequences[i].video){
          var video = { '_s' : 'video_'+i, 'title' : 'Video'+i,  '_src' : props.extraction.sequences[i].video._src};
          // eslint-disable-next-line
          local_videos.pushIfNotExist(video, function(e) {
                return e._src === video._src;
          });
        }
      }
      console.log("local videos",local_videos);

      this.state = {
                      open: false,
                      item: props.item,
                      local_videos : local_videos,
                    };
      this.handleClickOpen = this.handleClickOpen.bind(this);
      this.handleClose = this.handleClose.bind(this);
  }



  handleUpload = (file) => {
      // console.log("handleUpload", file);
    // Shamelessly inspired from https://medium.com/@kevinwu/client-side-file-upload-to-s3-using-axios-c9363ec7b530
    var filename =  (new Date()).getTime(); //this.props.data.id+'_'+this.props.item.index;
    var UploadUrlApi = process.env.REACT_APP_DATA_PROVIDER+'/uploads';
    var formData = new FormData();

    if(file){
    formData.append('file', file, [filename, file.name.split('.').pop()].join("."));
    }
    const axios = require("axios");
    var self = this;
    axios.post(UploadUrlApi,
      formData,
      {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      'Content-Type': 'multipart/form-data',
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        body: formData,
    })
    .then(function (result) {
      let video={
        _provider: "upload",
        _embed_type: "video",
        _src: result.data,
      };
      let videos=self.props.extraction.videos;
      if(videos.includes(video,0) === false){;
      videos.push(video);
      }
      self.props.input.onChange(videos);
      self.props.input.onChange(video);
      self.state.video = video;
      console.log("result :",self.props);
      self.forceUpdate();
      console.log("result 2:",self.props);
    })
    .catch(function (err) {
      console.error(err);
    });

  }
  handleClickOpen = () => {
    this.setState({
      open: true,
    });
  };

  handleClose = (obj) => {
    this.setState({ open: false, item: {...this.item, video: obj}});
    if (obj._src){
          this.props.input.onChange(obj);
    }
  };

    handleRemove = () => {
    this.setState({ open: false, item: {...this.item, video: {
                        _provider: "none",
                        _embed_type: "video",
                        _src: "noImage.png",
                      }}});
    this.props.input.onChange(null);
  };

  render() {
     const {
            classes,
            data
        } = this.props;
        var item = this.state.item;
        item.video = (item.video) ?
                      item.video :
                      {
                        _provider: "none",
                        _embed_type: "video",
                        _src: "noImage.png",
                      };
    /**/
    const videos = (data && data.formdata && data.formdata.extraction && data.formdata.extraction.videos) ? data.formdata.extraction.videos : [] ;
    let divId =  this.props.data ? this.props.data.id + '_'+ this.props.index : '';
    
    return (
      <div key={divId}>
        <CardMedia component={(item.video  && item.video._provider ==="youtube") ? "iframe" : ((item.video._embed_type) ? item.video._embed_type : "video")}
                className={classes.mediaVideo}
                src={item.video._src}
                title={item.title} controls={true} />

          <Tooltip title="Pick a Video as Background from the Video Library">
            <Button size="small" variant="outlined" className={classes.btnMedia} color="primary" onClick={() => { this.handleClickOpen()}  }>
             <VideoLibraryIcon />
             Video Library
            </Button>
          </Tooltip>

        <FileInput key={divId}  source="src" label="Sequence Video" accept="video/*" onChange={(files) => { this.handleUpload(files); } }>
          <FileField key={divId}  source="src" title="title" />
        </FileInput>

        {(item.video && item.video._provider !== 'none') ? (
          <Tooltip title="Remove this video from background">
            <Button variant="outlined" className={classes.btnMedia} color="red" onClick={() => { this.handleRemove()}  }>
              <DeleteIcon />
              Remove Video
            </Button>
          </Tooltip>) : (null)}



        <Dialog
          onClose={this.handleClose}
          fullWidth={true}
          maxWidth={false}
          aria-labelledby="customized-dialog-title"
          open={this.state.open}
        >
          <DialogTitle id="customized-dialog-title" onClose={this.handleClose}>
            Video Library
          </DialogTitle>
          <DialogContent>

            <GridList cellHeight={180} cols={5} className={classes.gridList}>
              <GridListTile key="Subheader" cols={5} style={{ height: 'auto' }}>
                {(videos.length > 0) ? (<ListSubheader component="div">videos From Your Content</ListSubheader>) : (null) }
              </GridListTile>
                {

                this.props.extraction.videos && this.props.extraction.videos.map((tile, key) => (

                  <GridListTile key={key} onClick={() => { this.handleClose(tile)} } className={classes.gridItem}>
                    {console.log("tile",tile)}
                    <CardMedia component={(tile._provider ==="youtube") ? "iframe" : "video"}
                      className={classes.mediaVideo}
                      src={tile._src}
                      title={item.title} />
                    <GridListTileBar
                      title={"Video "+(key+1)}
                      subtitle={<span>Click to use this Video</span>}
                      actionIcon={
                        <IconButton className={classes.icon}>
                          <InfoIcon />
                        </IconButton>
                      }
                    />
              </GridListTile>
            ))}
            </GridList>
            <SearchVideoBar onClose={(obj) => { this.handleClose(obj)} } data={this.props.data} extraction={this.props.extraction}/>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Done
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withStyles(styles)(VideoLibraryModale);
