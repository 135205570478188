
import React from 'react'
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import Typography from '@material-ui/core/Typography';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import ListSubheader from '@material-ui/core/ListSubheader';
import InfoIcon from '@material-ui/icons/Info';
import IconButton from '@material-ui/core/IconButton';


const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  "gridList": {
    paddingBottom : '3vh'
    /*width: 500,
    height: 450,*/
  },
  gridItem: {
    cursor: 'pointer',
    '&:hover': {
      'filter': 'brightness(120%)'
    }
  },
  users:{
    '& > a' : { color: "#fff"},
  },
  icon: {
    color: 'rgba(255, 255, 255, 0.54)',
  },
});


class SearchImageBar extends React.Component {

  constructor(props) {
      super(props);
        // console.log("SearchImageBar", props, props.extraction);
      let top_phrases = this.getTopPhrases();
      let local_images = [];
      for (var i = props.extraction.sequences.length - 1; i >= 0; i--) {
        local_images.push(props.extraction.sequences[i].image);
      }
      this.state = {term: top_phrases.length > 0 ? top_phrases[0] : '', local_images : local_images, images : [], rendered : 0};
  }
 getTopPhrases = () => {
    return  (this.props.extraction && this.props.extraction && this.props.extraction.nlp && this.props.extraction.nlp.top_phrases && this.props.extraction.nlp.top_phrases.length > 0) ? this.props.extraction.nlp.top_phrases :
    (this.props.extraction && this.props.extraction.sequences && this.props.extraction.sequences.length > 0 && this.props.extraction.sequences[0].nlp && this.props.extraction.sequences[0].nlp.top_phrases && this.props.extraction.sequences[0].nlp.top_phrases.length > 0 ) ? this.props.extraction.sequences[0].nlp.top_phrases : [];
 }

 onFormSubmit = (event) =>{
    var value = event.target.value;
    this.setState({term: value});
    if(!value || value.length < 3) return;
    event.preventDefault();
    this.onSearchSubmit(value, 1);
  }

handleClose = (url) => {
  this.setState({open: false});
  this.props.onClose(url); // call callback provided by parent
};

handleMore = () => {
  var page = (this.state.images.length / 10)+1;
  this.onSearchSubmit(this.state.term, page);
};

 onSearchSubmit = async (term, pagination) =>{
    let page = (pagination) ? pagination : 1;
    let response = await fetch(process.env.REACT_APP_DATA_PROVIDER+'/api/suggest/images?query='+term+'&page='+page);
    let responseData = await response.json();
    let imagesCollection = (page > 1) ? [...this.state.images,...responseData.items] : responseData.items;
    this.state.rendered = this.state.rendered++;
    this.setState({images: imagesCollection});
  }
  componentDidMount(prevProps, prevState){
    this.onSearchSubmit(this.state.term, this.state.pagination)
  }

  componentWillUnmount(prevProps, prevState){
    this.setState({ rendered : 0, images :[]});
  }


  render() {
    const {
      classes,
      //data
    } = this.props;
        return (
      <div className="ui segment">

        <GridList cellHeight={180} cols={5} className={classes.gridList}>
          <GridListTile key="Subheader" cols={5} style={{ height: 'auto' }}>
            <ListSubheader component="div" cols={3} >Search for Images From the web </ListSubheader>
            <ListSubheader component="div" cols={2} >
              <form onSubmit={this.onFormSubmit} className="ui form">
                <div className="field">
                  <TextField
                    id="standard-name"
                    label="Search from the Web"
                    className={classes.textField}
                    value={this.state.term}
                    onChange={(e)=>{this.onFormSubmit(e) } }
                    margin="normal"
                  />

                  </div>
              </form>
            </ListSubheader>

          </GridListTile>
            {

            this.state.images.map((image, key) => (
              <GridListTile key={image._s+'_'+key} onClick={() => { this.handleClose(image.largeImageURL)} } className={classes.gridItem}>
                <img src={image.previewUrl} alt={image.title} />
                <GridListTileBar
                  title={image.title}
                  subtitle={(
                            <Typography className={classes.users} variant="caption" gutterBottom>
                              <a href={image.source}>By {image.author}</a>
                            </Typography>)}
                  actionIcon={
                    <IconButton className={classes.icon}>
                      <InfoIcon />
                    </IconButton>
                  }
                />
              </GridListTile>
            ))}
        </GridList>
         <Button variant="outlined" color="secondary" onClick={() => { this.handleMore()}  }>
            Load More
          </Button>
      </div>
    );
  }
}




export default withStyles(styles)(SearchImageBar);
