import React, {Component} from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import Utils from "../lib/utils"

export class PageStatus extends Component {
    constructor(props) {
        super(props);
        let {record} = this.props;
        this.state = {
            status: record.status
        };
        // console.log('PS props',props, this.state);
    }

    async componentDidMount() {
      //this.setState({'status':'ok'});
      var {record} = this.props;
     if(record && record.id){
         this.doStatus();
     }
    }

    doStatus() {
        var {record} = this.props;
        var eventData;
        var eventSource = new EventSource(process.env.REACT_APP_DATA_PROVIDER+'/status/extraction/'+record.id);
        var that =this;
        var refresh = false;
        eventSource.onmessage = function(message) {
            eventData = JSON.parse(message.data);
            that.setState({'status': eventData.data.status})
            if(eventData.data.status === 'progress'){
                refresh = true;
            }
            window.PageStatus = window.PageStatus || {};
            window.PageStatus[record.id] = eventData.data.status;
        };
        eventSource.addEventListener("end", function(e) {
            //  // console.log('SHOULD CLOSE', eventSource, e);
            if(refresh === true){
                refresh = false;
                Utils.refresh(2000, 2);
            }
            //eventData = JSON.parse(e.data);
            //status = eventData.status;
            eventSource.close();
        }, false);
    }

  render() {
    let status = this.state.status;
    //  // console.log('props render', status, this.state);
    if (status === 'progress') {
      return (
        <CircularProgress style={{'height': '20px', 'width': '20px'}} label="Status"/>
      )
    } else if (status === 'failure') {
      return (
        <ErrorIcon label="Status" style={{color:'red'}}/>
      )
    } else {
      return (
        <CheckCircleIcon label="Status" style={{color:'green'}}/>
      )
    }
  }

}

export  default PageStatus;