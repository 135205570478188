import React, { Children, cloneElement, Component } from 'react';
import ReactDOM from 'react-dom'

import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
// import CardActions from '@material-ui/core/CardActions';
//import CardActionArea from '@material-ui/core/CardActionArea';

//import Collapse from '@material-ui/core/Collapse';
//import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import red from '@material-ui/core/colors/red';
import EditIcon from '@material-ui/icons/Edit';

import DeleteIcon from '@material-ui/icons/Delete';
//import MoreVertIcon from '@material-ui/icons/MoreVert';
import { withRouter } from 'react-router-dom'
import queryString from 'query-string'
//import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
// import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

  import { Draggable } from 'react-beautiful-dnd';
  import { translate } from 'ra-core';


  import FormInput from 'ra-ui-materialui/lib/form/FormInput';

  const styles = (theme) => { console.log("theme", theme);
    return ({
      line: {
          display: 'flex',
          listStyleType: 'none',
          borderBottom: `solid 1px ${theme.palette.divider}`,
          [theme.breakpoints.down('xs')]: { display: 'block' },
          '&.fade-enter': {
              opacity: 0.01,
              transform: 'translateX(100vw)',
          },
          '&.fade-enter-active': {
              opacity: 1,
              transform: 'translateX(0)',
              transition: 'all 500ms ease-in',
          },
          '&.fade-exit': {
              opacity: 1,
              transform: 'translateX(0)',
          },
          '&.fade-exit-active': {
              opacity: 0.01,
              transform: 'translateX(100vw)',
              transition: 'all 500ms ease-in',
          },
      },
      cardTopEditDelete:{
        margin: "7px 0 0 0",
        color: theme.palette.error.dark,
      },
      cardTopEditBar:{
        padding : "2px 4px",
      },
      itemCardHeader : {
        height : '45px',
        fontSize:'25%',

      },
      media : {
        "object-fit": "cover",
        height: "154px",
        backgroundColor : "#efefef",
        width : "100%",
        margin : "auto"
      },
      
      index: {
          width: '3em',
          paddingTop: '1em',
          [theme.breakpoints.down('sm')]: { display: 'none' },
      },
      form: { flex: 2 },
      action: {
          paddingTop: '0.5em',
      },
      leftIcon: {
          marginRight: theme.spacing(1),
      },
    item: {
      display: 'inline-block',
      width: '250px',
    },

    actions: {
      display: 'flex',
    },
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    },
    avatar: {
      backgroundColor: red[500],
    },
    modaleCloseButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },

  })};

  const getItemStyle = (isDragging, draggableStyle) => {
    return {
      // some basic styles to make the items look a bit nicer
      userSelect: "none",
      padding: isDragging ? "15px 15px 25px 15px" : "0px",
      margin:  isDragging ? "0px 5px" : "5px 5px" ,
      // change background colour if dragging
      background: isDragging ? "rgb(227, 255, 224, 0.7)" : "transparent",
      border: isDragging ? "2px dotted rgb(133, 209, 123, 0.7)" : "none",
      "listStyle": "none",
      "flex": "0 0 auto",

      // styles we need to apply on draggables
      ...draggableStyle
      }
  };

  export class DraggableFormInput extends Component {


      constructor(props) {
          super(props); 

          // Don't call this.setState() here!
          this.state = { expanded: (this.props.index === 0), open:false , openCancelDelete:false, nbitems : props.fields.length };

      };
      componentDidMount() {
        this.updateExpanded();

      }


      updateExpanded(){
        const qs = queryString.parse(this.props.location.search || '');
        const selectedSeq = parseInt(qs.seq,10) || 0;
        if(this.props.index === selectedSeq){
          this.setState(state => ({ expanded: (this.props.index === selectedSeq) }));
          const el = ReactDOM.findDOMNode(this);
          el.scrollIntoView();
        }
      }

      componentDidUpdate(prevProps) {
        if(prevProps.index !== this.props.index){
            this.updateExpanded();
        }
      }

      handleExpandClick = () => {
          this.setState(state => ({ expanded: !this.state.expanded }));
      };
      openModale = (isOppen) => {
          this.setOpen(true);
      };

      setOpen = (isOppen) => {
          this.setState(state => ({ open: isOppen, openCancelDelete: false }));
      };

      closeModale = () => {
       this.setOpen(false);
      };

      closeCancelDeleteModale = () => {
                  return this.setState(state => ({ open: false, openCancelDelete: false }));
      }
      openCancelDeleteModale =  () => {
                  this.setState(state => ({ open: false, openCancelDelete: true, nbitems : this.props.fields.length }));

      }

      render(){
          const {
              basePath,
              children,
              classes = {},
              id,
              index,
              member,
              item,
              onRemove,
              record,
              data,
              resource,
              translate,
          } = this.props;
          return (


          <Draggable key={id} draggableId={id} index={index}>
            {(provided, snapshot) => (
              <>
              {provided.placeholder}
              <div
                className={classes.item}
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                style={getItemStyle(
                  snapshot.isDragging,
                  provided.draggableProps.style
                )}
                  >
                <Card className={classes.card}>
                 <CardHeader className = {classes.cardTopEditBar}
                    avatar={
                          <Button className={classes.cardEditButton} size="small" color="primary" onClick={this.openModale}>
                            <EditIcon /> {translate('ra.action.edit')}
                          </Button>
                    }
                    action={
                          <Button size="small" onClick={this.openCancelDeleteModale} className={classes.cardTopEditDelete}>
                            <DeleteIcon />
                          </Button>
                    } />

                <CardMedia
                  className={classes.media}
                  component={(item.video  && item.video._provider ==="youtube" && item.video._src) ? "iframe" : (item.video && item.video._src) ? "video" : "img"}
                  src={(item.video && item.video._provider ==="youtube" && item.video._src) ? item.video._src + "?rel=0&autoplay=0&mute=1&controls=0&loop=1" : (item.video && item.video._src) ? item.video._src : item.image}
                  title={item.title}
                  onClick={this.openModale}
                />
                 <CardHeader
                   className = {classes.itemCardHeader}
                    title={item.title}
                    subheader={['Sequence',index+1].join(' ')}
                    onClick={this.openModale}
                    titleTypographyProps={{variant:'subtitle2' }}

                  />
                <Dialog open={this.state.open} onClose={this.closeModale} aria-labelledby="form-dialog-title"
                        fullWidth={true} maxWidth={"lg"}>
                 <DialogTitle id="form-dialog-title">Edit Sequence {index +1}  
                  <IconButton aria-label="close" className={classes.modaleCloseButton} onClick={this.closeModale}>
                    <CloseIcon />
                  </IconButton>
                </DialogTitle>
                <DialogContent>
                  <CardContent>
                    <Typography component="div">
                      <section className={classes.form}>
                      {Children.map(children, input => {
                        return (
                          <FormInput
                              basePath={basePath}
                              input={cloneElement(input, {
                                  source: `${member}.${input.props.source}`,
                                  data: {...data},
                                  item: {...item},
                                  label: input.props.label || input.props.source,
                              })}
                              record={record}
                              resource={resource}
                              width="100%"
                          />
                        )
                      }
                      )}
                      </section>
                    </Typography>
                  </CardContent>
                </DialogContent>
                <DialogActions>
                  <Button onClick={this.closeModale} size="large" variant="contained" color="secondary"> Done </Button>
                </DialogActions>
              </Dialog> 

              <Dialog open={this.state.openCancelDelete && this.state.nbitems === this.props.fields.length} onClose={this.closeCancelDeleteModale} aria-labelledby="form-dialog-title"
                        fullWidth={true} maxWidth={"xs"}>
               <DialogTitle id="confirmation-dialog-title"><Typography color="error">Delete Sequence {item.index+1}</Typography></DialogTitle>
                <DialogContent dividers>
                  <Typography color="error"> Are you Sure you want to delete Sequence {item.index+1}? This action is not undoable ...</Typography>
                </DialogContent>
                <DialogActions>
                  <Button autoFocus onClick={this.closeCancelDeleteModale} color="primary"> Cancel </Button>
                  <Button onClick={onRemove(index)} color="error">Ok</Button>
                </DialogActions>
              </Dialog>
            </Card>
          </div>
        </>
            )}

          </Draggable>
      )
      }
  };



  DraggableFormInput.propTypes = {
      basePath: PropTypes.string,
      children: PropTypes.node,
      classes: PropTypes.object,
      id: PropTypes.string,
      index: PropTypes.number,
      member: PropTypes.string,
      onRemove: PropTypes.func,
      record: PropTypes.object,
      resource: PropTypes.string,
      translate: PropTypes.func,
  };

  export default compose(translate, withStyles(styles))(withRouter(DraggableFormInput));
