import Cookies from 'js-cookie';
import Auth from './Auth.js';

const checkUserIsSuperAdmin = roles => roles.some(role => ['superAdmin'].indexOf(role) >= 0);
const checkUserIsAdmin = roles => roles.some(role => ['admin'].indexOf(role) >= 0);
const checkUserCanRawEdit = roles => userIsAdminOrSuperAdmin(roles);
const userIsAdminOrSuperAdmin = (roles = []) => checkUserIsAdmin(roles) || checkUserIsSuperAdmin(roles);

const checkUserCanCreate = (roles = [], acl = [], resource = '') =>
    userIsAdminOrSuperAdmin(roles) || acl.some(permission => ['.*', `${resource}.*`, `${resource}.create`].indexOf(permission) >= 0);

const checkUserCanRead = (roles = [], acl = [], resource = '') =>
    userIsAdminOrSuperAdmin(roles) || acl.some(permission => ['.*', `${resource}.*`, `${resource}.read`].indexOf(permission) >= 0);

const checkUserCanEdit = (roles = [], acl = [], resource = '') => {
    return userIsAdminOrSuperAdmin(roles) || acl.some(permission => ['.*', `${resource}.*`, `${resource}.update`].indexOf(permission) >= 0);
};

const checkUserCanDelete = (roles = [], acl = [], resource = '') =>
    userIsAdminOrSuperAdmin(roles) || acl.some(role => [`${resource}.*`, `${resource}.delete`].indexOf(role) >= 0);

const checkUserCanHaveAccess = (acl = []) => acl.length > 0;
const checkUserCanSeeListings = (acl = []) => checkUserCanHaveAccess(acl);

const authProvider = {
    login: payload => {

        //the user has not been logged yet : rejected
        if (!payload.token) {
            return Promise.reject();
        }

        //user has been logged and the payload contains the user detail we can set his session
        if(payload.authResult){
            Auth.setSession(payload.authResult);
        }

        return Promise.resolve();
    },
    logout: () => {
        return Promise.resolve();
    },
    checkAuth: () => {
        if (!Cookies.get('token')) {
            return Promise.reject();
        }
        return Promise.resolve();
    },
    checkError: error => {
        return Promise.resolve();
    },
    getPermissions: params => {
        // Setting permission
        const role = localStorage.getItem('role');
        const permission = localStorage.getItem('permission');
        const roles = role ? role.split(',') : [];
        const acl = permission ? permission.split(',') : [];

        //roles and permissions comes from local storage
        return (roles  && roles.length && acl && acl.length ) ? Promise.resolve({
            role,
            acl,
            userIsAdminOrSuperAdmin: userIsAdminOrSuperAdmin.bind(null, roles),
            checkUserCanRead: checkUserCanRead.bind(null, roles, acl),
            checkUserCanCreate: checkUserCanCreate.bind(null, roles, acl),
            checkUserCanDelete: checkUserCanDelete.bind(null, roles, acl),
            checkUserCanEdit: checkUserCanEdit.bind(null, roles, acl),
            checkUserIsSuperAdmin: checkUserIsSuperAdmin.bind(null, roles),
            checkUserCanRawEdit: checkUserCanRawEdit.bind(null, roles),
            checkUserCanHaveAccess: checkUserCanHaveAccess.bind(null, acl),
            checkUserCanSeeListings: checkUserCanSeeListings.bind(null, acl),
        }) : Promise.reject();
    },
};

export default authProvider;