
import React from 'react'
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import ListSubheader from '@material-ui/core/ListSubheader';
import InfoIcon from '@material-ui/icons/Info';
import IconButton from '@material-ui/core/IconButton';
import CardMedia from '@material-ui/core/CardMedia';


const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  "gridList": {
    paddingBottom : '3vh'
  },
  gridItem: {
    cursor: 'pointer',
    '&:hover': {
      'filter': 'brightness(120%)'
    }
  },
  users:{
    '& > a' : { color: "#fff"},
  },
  icon: {
    color: 'rgba(255, 255, 255, 0.54)',
  },
});


class SearchVideoBar extends React.Component {


 getTopPhrases = () => {
    return  (this.props.extraction && this.props.extraction && this.props.extraction.nlp && this.props.extraction.nlp.top_phrases && this.props.extraction.nlp.top_phrases.length > 0) ? this.props.extraction.nlp.top_phrases :
    (this.props.extraction && this.props.extraction.sequences && this.props.extraction.sequences.length > 0 && this.props.extraction.sequences[0].nlp && this.props.extraction.sequences[0].nlp.top_phrases && this.props.extraction.sequences[0].nlp.top_phrases.length > 0 ) ? this.props.extraction.sequences[0].nlp.top_phrases : [];
 }


  state = { term: this.getTopPhrases().length > 0 ? this.getTopPhrases()[0] : '',
            pagination : 1,
            videos : [],
            rendered : 0};

  onFormSubmit = (event) =>{
    var value = event.target.value;
    this.setState({term: value});
    if(!value || value.length < 3) return;
    event.preventDefault();
    this.onSearchSubmit(value, 1);
  }

handleClose = (obj) => {
  this.setState({...this.state, open: false});
  this.props.onClose(obj); // call callback provided by parent
};

handleMore = () => {
  var page = (this.state.videos.length / 10)+1;
  if(page > 1){
      this.onSearchSubmit(this.state.term, page);
  }
};

 onSearchSubmit = async (term, pagination) =>{
    let page = (pagination) ? pagination : this.state.pagination;
    let response = await fetch(process.env.REACT_APP_DATA_PROVIDER+'/api/suggest/videos?query='+term+'&page='+page);
    let responseData = await response.json();
    let tmpvideosCollection = [];
    for (var i = responseData.items.length - 1; i >= 0; i--) {
      tmpvideosCollection.push(
                              {
                                _provider:  responseData.items[i].provider ? responseData.items[i].provider : "proxy",
                                _embed_type: (responseData.items[i].provider  &&  responseData.items[i].provider === "youtube" )? "iframe" : "video",
                                _src: responseData.items[i].previewUrl,
                                title: responseData.items[i].title,
                                author: responseData.items[i].author,
                                source: responseData.items[i].source
                              });
    }
    let videosCollection = (page > 1) ? [...this.state.videos,...tmpvideosCollection] : tmpvideosCollection;
    this.setState({ rendered : this.state.rendered++, videos : videosCollection});

  }

  componentDidMount(prevProps, prevState){
    this.onSearchSubmit(this.state.term, this.state.pagination)
  }

  componentWillUnmount(prevProps, prevState){
    this.setState({ rendered : 0, videos :[]});
  }

  render() {
    const { classes } = this.props;
    return (
    <div className="ui segment">
      <GridList cellHeight={180} cols={5} className={classes.gridList}>
          <GridListTile key="Subheader" cols={5} style={{ height: 'auto' }}>
            <ListSubheader component="div" cols={3} >Search for Videos From the web </ListSubheader>
            <ListSubheader component="div" cols={2} >
              <form onSubmit={this.onFormSubmit} className="ui form">
                <div className="field">
                  <TextField
                    id="standard-name"
                    label="Search from the Web"
                    className={classes.textField}
                    value={this.state.term}
                    defaultValue="Ipad"
                    onChange={(e)=>{this.onFormSubmit(e) } }
                    margin="normal"
                  />

                  </div>
              </form>
            </ListSubheader>

          </GridListTile>
            {

            this.state.videos.map((video, key) => (
              <GridListTile key={video._s+'_'+key} onClick={() => { this.handleClose(video)} } className={classes.gridItem}>
              <CardMedia component={(video._provider ==="youtube") ? "iframe" : ((video._embed_type) ? video._embed_type : "video")}
                className={classes.mediaVideo}
                src={(video && video._provider ==="youtube") ? video._src + "?rel=0&autoplay=0&mute=1&controls=0&loop=1" : video._src}
                title={video.title}
                controls={true}/>
                <GridListTileBar
                  title={video.title}
                  subtitle={(
                            <Typography className={classes.users} variant="caption" gutterBottom>
                              <a href={video.source}>By {video.author}</a>
                            </Typography>)}
                  actionIcon={
                    <IconButton className={classes.icon}>
                      <InfoIcon />
                    </IconButton>
                  }
                />
              </GridListTile>
            ))}
        </GridList>
       <Button variant="outlined" color="secondary" onClick={() => { this.handleMore()}  }>
          Load More
        </Button>
    </div>
  );
}
}


export default withStyles(styles)(SearchVideoBar);
