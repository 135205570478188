// in src/App.js
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import { translate } from 'ra-core';
import withStyles from '@material-ui/core/styles/withStyles';
import authProvider from './components/Auth/authProvider';
import LoginPage from './components/Auth/LoginPage';
import LogoutButton  from './components/Auth/LogoutButton';
import SiteIcon from '@material-ui/icons/Web';
import VideoIcon from '@material-ui/icons/Theaters';
import translations  from './variables/translations';

import {
    fetchUtils,
    Admin,
    Resource,
//    EditGuesser
} from 'react-admin';
//import { UserList } from './users';
import { SiteList, SiteEdit, SiteCreate } from './sites';
import { PageList, PageEdit, PageCreate, PageShow } from './pages';
import {Dashboard} from './dashboard';
import restApiProvider from './dataProvider/rest';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import englishMessages from 'ra-language-english';
import customRoutes from './customRoutes'

const messages = {
    en: { ...translations, ...englishMessages,  },
};

  const i18nProvider = polyglotI18nProvider(locale => messages[locale], 'en');


const httpClient = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }
    // add your own headers here
    //options.headers.set('X-Custom-Header', 'foobar');
    //options.headers.set('Access-Control-Expose-Headers','Content-Range');
    return fetchUtils.fetchJson(url, options);
}
const dataProvider = restApiProvider(process.env.REACT_APP_DATA_PROVIDER, httpClient);

const styles = theme => ({


});

export class App extends Component {
    constructor(props) {
      //  // console.log(props.classes.cardsContainer);
      const styleTag = document.createElement("style");
      const cssTemplateString = [ //fix waiting for https://github.com/marmelab/react-admin/pull/3045 to be merged
                                  '.show-page div[class*=MuiFormControl] {display: inline;}',
                                  '.edit-page div[class*=MuiFormControl] {display: grid;}',
                                  '.layout, div[class*=Layout-] { min-width: 0; }',
                                ].join('\n');
      styleTag.innerText = cssTemplateString;
      window.document.head.insertAdjacentElement('beforeend', styleTag);
      window.credentials = {"superAdmin":[".*"],"admin":[".*"],"premium":["sites.*", "pages.*"],"regular":["sites.*", "pages.*"], "pending" : []};

      super(props);
    }
    render() {
          const {
         classes,
      } = this.props;

        return(
        <Admin 
            dashboard={Dashboard}
            dataProvider={dataProvider}
            className={classes.appRoot}
            i18nProvider={i18nProvider}
            authProvider={authProvider}
            loginPage={LoginPage}
            logoutButton={LogoutButton}
            customRoutes={customRoutes}
            >
             {permissions => [
            (permissions && permissions.role && permissions.role !== 'pending') ? <Resource name="sites" list={SiteList} edit={SiteEdit} create={SiteCreate} icon={SiteIcon} /> : null,
            <Resource name="pages" list={PageList} edit={PageEdit} show={PageShow} create={PageCreate} icon={VideoIcon}/>
            ]}

        </Admin>
        );

    }
}

App.propTypes = {
    classes: PropTypes.object,
    className: PropTypes.string,
    translate: PropTypes.func,
};

export default compose(translate, withStyles(styles))(App);
