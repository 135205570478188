import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import Auth from './components/Auth/Auth.js';

export default [
  <Route exact path="/access_token=:id"> <Redirect to='/' /> </Route>,
  <Route exact path="/logout" component={() => {
    Auth.signOut()
    return null;
  }} />
];