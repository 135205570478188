import React, { Component } from 'react';
import { connect } from 'react-redux';
import { userLogin } from 'react-admin';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Autorenew from '@material-ui/icons/Autorenew';
import LogoutButton from './LogoutButton';
import Auth from './Auth.js';

const theme = createMuiTheme({
    palette: {
        type: 'light',
    },
});

const styles = them => ({
    progress: {
        margin: them.spacing.unit * 2,
    },
    loader: {
        textAlign: 'center',
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
    }
});

class LoginPage extends Component {
    state = {
        loading: true,
        message: 'Login, please wait...',
        retry: false,
    };

    componentDidMount() {
        Auth.checkSession((payload) => {
            console.log('checksessionpayload', payload);
            //if user is pending he has no roles (empty array)
            if ( payload && payload.roles && !payload.roles.length) {
                this.setState({
                    loading: false,
                    message: 'Your account is pending validation.',
                    retry: true,
                });
                return;
            }

            // Else We connect the User and the UI Login
            this.props.userLogin(payload);

            return;
        });
    }

    render() {
        const { loading, message, retry } = this.state;
        const { classes } = this.props;
        return (
            <MuiThemeProvider theme={theme}>
                <CssBaseline />
                <div className={classes.loader}>
                    <Typography variant="h3" gutterBottom>
                        {message}
                    </Typography>
                    {loading && <CircularProgress className={classes.progress} color="secondary" />}
                    {retry && <LogoutButton Icon={Autorenew} label="Login" />}
                </div>
            </MuiThemeProvider>
        )
    }
}

export default connect(undefined, { userLogin })(withStyles(styles)(LoginPage));