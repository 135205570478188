import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import CollectionsIcon from '@material-ui/icons/Collections';

import Typography from '@material-ui/core/Typography';
import {
    ImageField,
    ImageInput,
} from 'react-admin';

import CardMedia from '@material-ui/core/CardMedia';
import CardActions from '@material-ui/core/CardActions';

import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import ListSubheader from '@material-ui/core/ListSubheader';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import SearchImageBar from './SearchImageBar';


const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    /*width: 500,
    height: 450,*/
  },
  media : {
    "object-fit": "contain",
    maxHeight: "279px",
    minHeight: "279px",
    width : "100%",
    margin : "15px auto"
  },

  btnMedia:{
    fontSize:"75%",
    textAlign: "center",
  },
  mediaVideo : {
    "object-fit": "contain",
    maxHeight: "279px",
    width : "100%",
    minHeight : "279px",
    margin : "15px auto"
  },
  gridItem: {
    cursor: 'pointer',
    '&:hover': {
      'filter': 'brightness(120%)'
    }
  },
  icon: {
    color: 'rgba(255, 255, 255, 0.54)',
  },
});


const DialogTitle = withStyles(theme => ({
  root: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}))(props => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography variant="body2">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    borderTop: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

// eslint-disable-next-line
Array.prototype.inArray = function(comparer) {
    for(var i=0; i < this.length; i++) {
        if(comparer(this[i])) return true;
    }
    return false;
};

// eslint-disable-next-line
Array.prototype.pushIfNotExist = function(element, comparer) {
    if (!this.inArray(comparer)) {
        this.push(element);
    }
};


class ImageLibraryModale extends React.Component {
  constructor(props) {

      super(props);

      let local_images = [];
      let sequences_images = (props.extraction && props.extraction.sequences) ? props.extraction.sequences : [];
      for (var i = sequences_images.length - 1; i >= 0; i--) {
        var image = { '_s' : 'image_'+i, 'title' : 'Image'+i,  '_src' : props.extraction.sequences[i].image};
        // eslint-disable-next-line
        local_images.pushIfNotExist(image, function(e) {
              return e._src === image._src;
        });
      }

      this.state = {
      open: false,
      image: (props.item.image) ? props.item.image : "noImage.png",
      local_images : local_images,
    };
      this.handleClickOpen = this.handleClickOpen.bind(this);
      this.handleClose = this.handleClose.bind(this);
  }



  handleUpload = (file) => {
      // console.log("handleUpload", file);
    // Shamelessly inspired from https://medium.com/@kevinwu/client-side-file-upload-to-s3-using-axios-c9363ec7b530
    var filename =  (new Date()).getTime(); //this.props.data.id+'_'+this.props.item.index;
    var UploadUrlApi = process.env.REACT_APP_DATA_PROVIDER+'/uploads';
    var formData = new FormData();
    if(file){
      formData.append('file', file, [filename, file.name.split('.').pop()].join("."));
      }
    const axios = require("axios");
    var self = this;

    axios.post(UploadUrlApi,
      formData,
      {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      'Content-Type': 'multipart/form-data',
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        body: formData,
    })
    .then(function (result) {
      self.props.input.onChange(result.data);
      self.state.image = result.data;
      self.forceUpdate();
    })
    .catch(function (err) {
      console.error(err);
    });

  }

  handleClickOpen = () => {
    this.setState({
      open: true,
    });
  };

  handleClose = (imageUrl) => {
    this.setState({ open: false, image : imageUrl });
    this.props.input.onChange(imageUrl);
  };
  render() {
     const {
            classes,
            extraction,
            item
           } = this.props;

    const images = this.state.local_images ;
    item.image = (item.image) ? item.image : "noImage.png";
    let divId =  this.props.data ? this.props.data.id + '_'+ this.props.index : '';
    return (
      <div key={divId}>
        <CardMedia
          component={"img"}
          className={classes.media}
          src={this.state.image}
          title={item.title}
          />


         <CardActions >
              <Tooltip title="Pick a new Image from the Image Library">
                <Button size="small" variant="outlined" className={classes.btnMedia} color="secondary" onClick={() => { this.handleClickOpen()}  }>
                  <CollectionsIcon /> <span>Image Library</span>
                </Button>
              </Tooltip>
        </CardActions>


        <ImageInput source="pictures" label="Sequence Picture" accept="image/*" onChange={(files) => { this.handleUpload(files); } }>
          <ImageField source="src" title="title" />
        </ImageInput>

        <Dialog
          onClose={this.handleClose}
          fullWidth={true}
          maxWidth={false}
          aria-labelledby="customized-dialog-title"
          open={this.state.open}
        >
          <DialogTitle id="customized-dialog-title" onClose={this.handleClose}>
            Image Library
          </DialogTitle>
          <DialogContent>

            <GridList cellHeight={180} cols={5} className={classes.gridList}>
              <GridListTile key="Subheader" cols={5} style={{ height: 'auto' }}>
                <ListSubheader component="div">Images From Your Content</ListSubheader>
              </GridListTile>
                {
                images.map((tile, key) => (
                  <GridListTile key={tile._s} onClick={() => { this.handleClose(tile._src)} } className={classes.gridItem}>
                    <img src={tile._src} alt={tile.title} />
                    <GridListTileBar
                      title={"Image "+(key+1)}
                      subtitle={<span>Click to use this Image</span>}
                      actionIcon={
                        <IconButton className={classes.icon}>
                          <InfoIcon />
                        </IconButton>
                      }
                    />
              </GridListTile>
            ))}
            </GridList>
            <SearchImageBar onClose={(imageUrl) => { this.handleClose(imageUrl)} } data={this.props.data} extraction={extraction} />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Done
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}


//export
export default withStyles(styles)(ImageLibraryModale);
