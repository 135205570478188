import auth0Client from '../components/Auth/Auth';

const Utils = {
    refresh(timeout, many){
        let count = 1;
        timeout = timeout ? timeout : 1000;
        let intervalId =  setInterval(function () {
            let refreshButton = document.querySelector('button[title="Refresh"]');
            //  // console.log('SetInterval', intervalId, refreshButton, count+'/'+many);
            if(refreshButton){
                refreshButton.click();
            }
            count++;
            if(many && count > many){
                clearTimeout(intervalId);
            }
        }, timeout);


    },

    getCompany(v){
        return (auth0Client.getRole() !== 'admin') ? auth0Client.getCompanySlug() : (v) ? v : null;
    },
}

export default Utils;
