export default class analyticsData {
    constructor(provider) {
        this.provider = provider
        this.metrics = [{name:"totalEvents"} ,{name:"eventValue"},{name:"uniqueEvents"}]
        this.data = {}
    }
   
    async init(start,end){
        if(this.provider === "google") {
            let query = process.env.REACT_APP_DATA_PROVIDER+'/api/analytics?metric=ga:totalEvents,ga:eventValue,ga:uniqueEvents&start='+start+'&end='+end+'&dbd=true'
            await fetch(query)
            .then(response => response.json())
            .then(
                (result) => {
                    this.data = result 
                });
        }
    }

    stringToDate(string){
        string = string.replace(/-/g,'')
        var year = string.substring(0,4);
        var month = string.substring(4,6);
        var day = string.substring(6,8);
        var date = new Date(year, month-1, day);
        return date
    }
 

    getDates(startDate, stopDate) {
        var dateArray = [];
        var currentDate = startDate;
        while (currentDate <= stopDate) {
            var day = new Date (currentDate)
            dateArray.push(day);
            currentDate.setDate(currentDate.getDate() + 1);
        }
        return dateArray;
    }

    getDataByDay(){
        if(!(this.data.hasOwnProperty("rows"))){
            return {}
        }
            let data = this.data.rows
            let el = {};
            data.forEach(row => {
                //level 1
                let dateKey = row.dimensions[2];
                let actionKey = row.dimensions[0];
                let labelKey = row.dimensions[1];
                let metric1Val = parseInt(row.metrics[0].values[0]);
                el[dateKey] = el[dateKey] || {"total":0,"actions":{}};
                el[dateKey].actions[actionKey] = el[dateKey].actions[actionKey] || {"total": 0, "labels":{}};
                el[dateKey].actions[actionKey].labels[labelKey] = el[dateKey].actions[actionKey].labels[labelKey] || {"total":0};
                
                //action object (level 2)
                el[dateKey].total += parseInt(metric1Val);
                el[dateKey].actions[actionKey].total += metric1Val;
                el[dateKey].actions[actionKey].labels[labelKey].total = metric1Val;
            });
            return el
    }

    getMetrics(){
        let data = this.data.totals
        var el={};
        var tab = [];
        let labelSize = data ? data[0].values.length : 0
        for (let index = 0; index < labelSize; index++) {
            el={}
            el["name"]=this.metrics[index].name;
            el["value"]=data[0].values[index];
            tab.push(el);
        }
        return tab
    }

    getTableData(){
        if(!(this.data.hasOwnProperty("rows"))){
            return []
        }
        let el = [];
        let data = this.data.rows
        data.forEach(element => {
            let actionKey = element.dimensions[0];
            el[actionKey] = el[actionKey] || {}
            for (let index = 0; index < this.metrics.length; index++) {
                let metricVal = parseInt(element.metrics[0].values[index]);
                let val = el[actionKey][this.metrics[index].name] ? el[actionKey][this.metrics[index].name] + metricVal : metricVal
                el[actionKey][this.metrics[index].name] = val
            }
        });
        return el
    }

    getGraph(actions,start,end){
        let data= this.getDataByDay()
        //get all days between 2 date
        var dateStart = this.stringToDate(start);
        var dateEnd = this.stringToDate(end);
        var labels = this.getDates(dateStart,dateEnd);
        var multipleSeries = []
        actions.forEach(action => {
            var series = [];
            labels.forEach((date,i) => {
                for (const propertyDate in data) {
                    let dayData = this.stringToDate(propertyDate)
                    if(date.getTime() === dayData.getTime()){
                        for (const propertyAction in data[propertyDate].actions) {
                            if(propertyAction === action){
                                series[i]= data[propertyDate].actions[propertyAction].total
                            }
                        }
                    }else{
                        let val = series[i]
                        !(series[i]>0) ? series[i]=0 : series[i]=val
                    }
                }
            });
            multipleSeries.push(series)
        });
        labels.forEach((element,i) => {
            labels[i] = element.getDate()
        });
        let goodData ={"series":multipleSeries,"labels":labels}
        return goodData
    }


  }
  
  