import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});



export default function DataTable(props) {
  const classes = useStyles();
  let rows = [];
  rows = props.data;
  if(Object.keys(props.data).length<1){
    return []
}
  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
          <TableCell >Labels</TableCell>
          {Object.keys(rows[Object.keys(rows)[0]]).map((key,j) => <TableCell key={j} align="right">{key}</TableCell>)}
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.keys(rows).map((row,i) => ( 
            <TableRow key={i}>
              <TableCell component="th" scope="row">
                {row}
              </TableCell>
              {Object.keys(rows[row]).map((key,k) => <TableCell key={k} align="right">{rows[row][key]}</TableCell>)}
            </TableRow>
          ))}
        </TableBody>
     </Table>
    </TableContainer>
  );
}