import React, {Component} from 'react';
//import ReactDOM from 'react-dom'
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
//import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import AutoRenewIcon from '@material-ui/icons/Autorenew';
import {TextInput, NumberInput, minValue} from 'react-admin';
import VideoPreview from '../Preview/VideoPreview';
import Tooltip from '@material-ui/core/Tooltip';
import LinearProgress from '@material-ui/core/LinearProgress';
import AlarmIcon from '@material-ui/icons/Alarm';
import CircularProgress from '@material-ui/core/CircularProgress';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import AudioPlayerObject from "./AudioPlayerObject";


const styles = theme => { return {
    root: {
        "&.sticky": {
            position: "fixed",
            right: "25px",
            bottom: "50px",
            maxWidth: "calc(30% - 50px)",
        },
        [theme.breakpoints.down('md')]: {
            position: "relative",
            right: 0,
            maxWidth: "100%"
        }
    },
    totalDuration:{
        fontSize : '15px',
        color : theme.palette.text.disabled,
        display: 'flex',
        alignItems: 'center',
        paddingTop: 10
    },
    AdvancedSettings : {
        margin : "15px 0",
    },
    chip: {
        marginRight: theme.spacing(1),
    },
    section1: {
        margin: `${theme.spacing(3)}px ${theme.spacing(2)}px`,
    },
    block : {
     display: 'block !important'
    },
    section2: {
        margin: theme.spacing(2),
    },
    section3: {
        margin: "0 5px -25px 0",
    },
    buttonProgress: {
        color: 'green',
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    barProgress: {
        marginTop: 12
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    colorSecondary: {
        backgroundColor: 'red'
    },
    generateStatus : {
        verticalAlign: 'middle',
        paddingLeft: 10
    },
    saveButton: {
        float : 'right'
    }
}
};




export class VideoSettings extends Component {


    constructor(props) {
        super(props);

        this.state = {
            generateStatus : 'progress',
            generateLoading: false,
            generateCompleted1: 0,
            generateCompleted2: 0,
            ts: 1,
            duration: this.getDuration()
        };

    }


    async componentDidMount() {
        /*const el = ReactDOM.findDOMNode(this);
        window.addEventListener('scroll', function (e) {
            // Get the offset position of the navbar
            if (window.pageYOffset > 150) {
                el.classList.add("sticky");
            } else {
                el.classList.remove("sticky");
            }
        }, false);*/
        this.checkVideoStatus();
    }

    checkVideoStatus = (tpl) => {
        const id = this.props.idPage;
        let that = this;
        that.setState({
            generateStatus: 'progress',
            generateLoading: false
        });
        //console.log('tpl',tpl)
        let template = tpl ? tpl : ((this.props.input && this.props.input.value) ? this.props.input.value.template : 'template1');
        console.log('checking Video Status for ', template);
        let statusInterval = setInterval(async function() {
            let checkingTemplate = template;
            fetch(process.env.REACT_APP_DATA_PROVIDER + `/api/videos?page_id=${id}&template=${template}`)
                .then(res => res.json())
                .then((data) => {
                    let status = null;
                    let contextTemplate = (that.props.input && that.props.input.value) ? that.props.input.value.template : 'template1';
                    if (data && data.docs && data.docs[0]) {
                        status = data.docs[0].status;
                        console.log('Video Status for ', checkingTemplate, status);
                    }
                    if(status !== 'progress'){
                        clearInterval(statusInterval);
                    }
                    //Update the state only if selected template === checkingTemplate
                    if(checkingTemplate === contextTemplate) {
                        that.setState({
                            generateStatus: status,
                            generateLoading: status === 'progress' ? true : false,
                            ts: status === true ? new Date().getTime() : that.state.ts
                        });
                    }
                });
        }, 3000);
    }

    handleSave = (e)=>{
        if(this.props.form){
            this.props.form.submit();
            return
        }
    }


    handleGenerateClick = () => {
        const id = this.props.idPage;
        //const {extraction} = this.props;
        let formValues = this.props.form.getState().values;
        let timePerSequence =  formValues.time_per_seq ? formValues.time_per_seq : 3;
        let template = (this.props.input && this.props.input.value) ? this.props.input.value.template : 'template1';
        //let nbSequences = extraction && extraction['sequences'] ? extraction['sequences'].length : 0;
        //default duration
        let duration = (this.getDuration(template));

        //Step is just to help the progress bars no to be too fast
        //let step = duration / 10;
        //  // console.log('TOTAL GENERATION TIME:', duration);
        //  // console.log('STEP:', step);
        if (!this.state.generateLoading) {
            this.setState(
                {
                    generateStatus: 'progress',
                    generateLoading: true,
                    generateCompleted1: 0,
                    generateCompleted2: 0
                },
                async () => {
                    //Render templates from sequences
                    //include the offset of 1s added by the html template css/js slideshow
                    //let tps = timePerSequence > 1 ? timePerSequence -1 : timePerSequence;
                    //Prepare Video sequences rendering
                    let response1 = await fetch(process.env.REACT_APP_AI_API_ENDPOINT+`/extractor/render/${id}?tps=${timePerSequence}`);
                    await response1.text();
                    //  // console.log(responseData1);
                    //Cast templates to video
                    this.setState({
                        generateCompleted1: 100
                    });

                    //Do the final Video
                    let response2 = await fetch(process.env.REACT_APP_AI_API_ENDPOINT+`/videos/cast/${id}?duration=${duration}&template=${template}`);
                    console.log('response2',response2);
                    //Fetch status
                    let that = this;
                    let statusInterval = setInterval(async function(){
                        let checkingTemplate = template;
                        fetch(process.env.REACT_APP_DATA_PROVIDER+`/api/videos?page_id=${id}&template=${template}`)
                            .then(res => res.json())
                            .then((data) => {
                                let status = 'progress';
                                let contextTemplate = (that.props.input && that.props.input.value) ? that.props.input.value.template : 'template1';
                                if(data && data.docs && data.docs[0]) {
                                    status = data.docs[0].status;
                                }
                                if(status !== 'progress' && checkingTemplate === contextTemplate){
                                    clearInterval(statusInterval);
                                    that.setState({
                                        generateStatus : status,
                                        generateCompleted2: 100,
                                        generateLoading: false,
                                        ts: new Date().getTime()
                                    });
                                }
                                console.log('Generate Status for',checkingTemplate, status)
                                // do something with JSON
                            });

                    }, 3000);
                    //await response2.text();
                    //  // console.log('Cast finish',responseData2, this.state);



                    //  // console.log('after generate', this.state);
                    //self.forceUpdate();
                    //Utils.refresh(2000,1);
                },
            );
        }
    };

    getDuration = (template) => {
        const {extraction} = this.props;
        let formValues = this.props.form.getState().values;
        let timePerSequence =  formValues.time_per_seq ? formValues.time_per_seq : 3;
        let nbSequences = extraction && extraction['sequences'] ? extraction['sequences'].length : 0;
        if(template === "template2") {
            //template2 = the reveal template and have a duration based on  SUM(nbSentences per sequences)
            let nbSentences = 0;
            let sentences;
            extraction['sequences'].forEach(function(item){
                if(item.highlighted_text){
                    sentences = item.highlighted_text.match( /[^\.!\?]+[\.!\?]+/g );
                    nbSentences += sentences ? sentences.length : 1;
                }
            })
            nbSequences = nbSentences;
        }
        let duration = (nbSequences + 2) * (timePerSequence);
        return duration;
    }

    formatDuration = (time) => {
        // Hours, minutes and seconds
        var hrs = ~~(time / 3600);
        var mins = ~~((time % 3600) / 60);
        var secs = ~~time % 60;

        // Output like "1:01" or "4:03:59" or "123:03:59"
        var ret = "";
        if (hrs > 0) {
            ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
        }
        ret += "" + mins + ":" + (secs < 10 ? "0" : "");
        ret += "" + secs;
        return ret;
    }

    render() {
        const {classes, ...rest} = this.props;
        const {generateStatus, generateLoading, generateCompleted1, generateCompleted2} = this.state;
        return (
            <div className={classes.root}>


                <div className={classes.section1}>
                    <Tooltip title="You can control here your end video behaviour and Look and feel and re-generate it according to the following settings.">
                        <Typography gutterBottom variant="subtitle2">
                            Video Settings
                        </Typography>
                    </Tooltip>
                </div>

                <Grid container
                      direction="row"
                      justify="space-between"
                      spacing={1}>
                    <Grid item xs={8}>
                        <VideoPreview {...rest} source="video" ts={this.state.ts} videoStatus={this.checkVideoStatus.bind(this)}/>
                        {
                            /*
                            <Typography gutterBottom variant="subtitle2">
                                Current bla bla
                            </Typography>
                            <div>
                                <Chip className={classes.chip} label="Extra Soft"/>
                                <Chip className={classes.chip} label="Soft"/>
                                <Chip className={classes.chip} label="Medium"/>
                                <Chip className={classes.chip} label="Hard"/>
                            </div>
                            */
                        }
                        <Divider variant="middle"/>
                        <Grid
                            container
                            direction="row"
                            justify="space-between"
                            alignItems="center"
                        >
                            <Grid item sm={12} md={6} xs="auto">
                                <div className={classes.wrapper}>
                                    <Button variant="outlined" color="primary" className="btnGenerate" disabled={generateLoading || generateStatus === 'progress'}
                                            onClick={this.handleGenerateClick}>
                                        <AutoRenewIcon/> Generate
                                    </Button>
                                    {generateStatus === true && <CheckCircleIcon label="Status" className={classes.generateStatus} style={{color:'green'}} />}
                                    {generateStatus === false && <ErrorIcon label="Status" className={classes.generateStatus} style={{color:'red'}} />}
                                    {generateStatus === 'progress' && <CircularProgress   style={{'height': '20px', 'width': '20px', 'vertical-align':'middle','margin-left':'10px'}} label="Status" />}
                                    {/*generateLoading && <CircularProgress size={24} className={classes.buttonProgress} /> */}
                                    {generateLoading && <LinearProgress variant="determinate" value={generateCompleted1}
                                                                        className={classes.barProgress}/>}
                                    {generateLoading &&
                                    <LinearProgress color="secondary" variant="indeterminate" value={generateCompleted2}
                                                    className={classes.barProgress}/>}

                                </div>
                            </Grid>

                        </Grid>
                    </Grid>
                    <Grid item xs={4}>
                        <Card>
                            <CardHeader title="Advanced" />
                            <CardContent>
                                <Grid
                                    container
                                    direction="column"
                                    justify="flex-start"
                                    alignItems="stretch"
                                >
                                    <Typography paragraph>
                                        <TextInput variant="outlined" source="target_selector" className={classes.block} label="Target Selector" placeholder="body > p" helperText="CSS Selector to define where the video should appear In Page - Overrides the Site default one" />
                                    </Typography>
                                    <Typography paragraph>
                                        <NumberInput variant="outlined" defaultValue={3}  validate={[minValue(1)]} source="time_per_seq" helperText="Time pers sequence (in seconds)" label="Time per sequence" type="number" onChange={()=>(this.setState({duration: this.getDuration()}))} />
                                    </Typography>
                                    <Typography paragraph>
                                        <AudioPlayerObject {...rest} />
                                    </Typography>
                                </Grid>
                                <Grid xs="auto">

                                        <Button className={classes.saveButton} variant="contained" color="primary" onClick={() => { this.handleSave();}}>
                                            Save
                                        </Button>

                                        <Tooltip title="Expected Video Duration length in [minutes:seconds]" style={{"textAlign": "right"}}>
                                            <Typography className={classes.totalDuration} ><AlarmIcon /> {"Total Duration ["+ this.formatDuration(this.state.duration)+"]"} </Typography>
                                        </Tooltip>

                                </Grid>

                            </CardContent>
                        </Card>


                    </Grid>
                </Grid>

            </div>
        );


    }

}

VideoSettings.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(VideoSettings);

