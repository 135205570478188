
import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    EditButton,    
    SaveButton,
    DeleteButton,
    Edit,
    Create,
    SimpleForm,
    TextInput,
    UrlField,
    TabbedForm,
    Toolbar,
    FormTab,
    ImageField,
    ImageInput
} from 'react-admin';
import { Field } from 'react-final-form';
import ColorInput from '../components/CustomInput/ColorInput';
import FontsInput from '../components/CustomInput/FontsInput';
import SanetizedGrid from '../components/Grid/SanetizedGrid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import auth0Client from '../components/Auth/Auth';
import Utils from '../lib/utils'
import { useForm } from 'react-final-form';


export const SiteList = props => (
    <List {...props}>
        <Datagrid>
            <TextField variant="outlined" source="title" />
            <TextField variant="outlined" source="domain" />
            <UrlField  source="url" />
            <EditButton />
            <DeleteButton/>
        </Datagrid>
    </List>
);

const SiteEditStyles = theme => ({
     root: {},
    ColorsPanelsClass: {
        width:'100%',
        margin : '15px 0!important',
         
    },
    ColorsPanels :{
            display: 'inline-block'
        },
    FontsPanel:{
        width:'100%',
        
    },
    FontsPicker:{
            display: 'inline-block',
            margin: '0 5px'
        },
  
});


const SiteEditActions = (props) =>  {
  return (
     <Toolbar {...props}>
          <SaveButton {...props} />
          <DeleteButton {...props} />
      </Toolbar>
  )
}




const LogoInput = props => {
    const form = useForm(props);
    const formValues = form.getState().values;
    var logo = formValues.logo ? formValues.logo : {};
    var handleUpload = function(file){
    // Shamelessly inspired from https://medium.com/@kevinwu/client-side-file-upload-to-s3-using-axios-c9363ec7b530
    var filename = 'logo_'+props.logoId;
    var UploadUrlApi = process.env.REACT_APP_DATA_PROVIDER+'/uploads';
    var formData = new FormData();
    formData.append('file', file, [filename, file.name.split('.').pop()].join("."));
    const axios = require("axios");

    axios.post(UploadUrlApi,
      formData,
      {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      'Content-Type': 'multipart/form-data',
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        body: formData,
    })
    .then(function (result) {
        logo.src = result.data;
        logo.rawFile = {path : result.data};
        form.change('logo', logo)
    })
    .catch(function (err) {
      console.error(err);
    });

    }
    return (
        <ImageInput source="logo" label="Logo" accept="image/*" onChange={(files) => { handleUpload(files); }}>
            <ImageField source="src" title="Logo" />
        </ImageInput>
    );
}

class SiteEditClass extends React.Component {

  

 render(){
    const props = this.props;
    const {classes, basepath, hasCreate, hasShow, hasEdit, hasList, ...rest} = this.props;
    const {ColorsPanelsClass, ColorsPanels, FontsPicker, FontsPanel} = classes;
    return (
    <Edit {...props} className={classes.SiteEdit}>
        <TabbedForm toolbar={<SiteEditActions {...rest}/>} >
            <FormTab label="Site">
                <SanetizedGrid container spacing={1} className="row" >
                    <SanetizedGrid item sm={12} lg={4} className="leftContainer">
                        <TextInput variant="outlined" disabled source="id" />
                        <TextInput variant="outlined" source="title" label="Name" />
                        <TextInput variant="outlined" source="domain" label="Domain" />
                        <TextInput variant="outlined" source="url" label="URL" />
                        <TextInput variant="outlined" source="ga_account" label="GA Account" placeholder="UA-XXXXXXX-YY" helperText="Enter your GA Account to get events on your videos, allevents will be prefixed by CC-" />
                    </SanetizedGrid>
                    <SanetizedGrid item sm={12} lg={8}>
                        <Typography >Branding</Typography>
                        <LogoInput logoId={props.id} onChange={(files) => { this.handleUpload(files); } } />
                    <React.Fragment>
                        <div className={ColorsPanelsClass}>
                            <Field name="PrimaryColor" source="PrimaryColor" component={ColorInput}  className={ColorsPanels} />
                            <Field name="SecondaryColor" source="SecondaryColor" component={ColorInput} className={ColorsPanels} />
                            <Field name="TextColor"  source="TextColor" component={ColorInput} className={ColorsPanels} />
                        </div>
                    </React.Fragment>
                    <div className={FontsPanel}>
                        <Field name="Fonts" className={FontsPicker} source="Fonts" component={FontsInput} />
                    </div>
                    <TextInput variant="outlined" source="target_selector" label="Target Selector"  placeholder="body > p" helperText="CSS Selector to define where the video should appear In Page" />
                    </SanetizedGrid>
                </SanetizedGrid>
            </FormTab>
            <FormTab label="Meta">
                <TextInput variant="outlined" disabled source="id" />
                <TextInput variant="outlined" disabled source="domain" />
                <TextInput variant="outlined" disabled source="url" />
                <TextInput variant="outlined" disabled source="updated_by" value={auth0Client.getProfile().email} />
                <TextInput variant="outlined" disabled source="user_id" value={auth0Client.getProfile().sub} />
                <TextInput variant="outlined" disabled source="updatedAt" />
                <TextInput variant="outlined" disabled source="company" format={Utils.getCompany} parse={Utils.getCompany} />
            </FormTab>
        </TabbedForm>
    </Edit>
    )
  }
}

export const SiteEdit = withStyles(SiteEditStyles)(SiteEditClass);


export const SiteCreate = props => (
    <Create  {...props}>
        <SimpleForm redirect="list">
            <TextInput variant="outlined" source="title" />
            <TextInput variant="outlined" source="domain" />
            <TextInput variant="outlined"  source="url" />
        </SimpleForm>
    </Create>
);