import React, { Component } from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import { translate } from 'ra-core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import withStyles from '@material-ui/core/styles/withStyles';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import Avatar from '@material-ui/core/Avatar';
import SlideshowIcon from '@material-ui/icons/Slideshow';
import ContentCreateIcon from '@material-ui/icons/Create';
import Typography from '@material-ui/core/Typography';
import CardActions from '@material-ui/core/CardActions';

import VideoPreview from '../components/Preview/VideoPreview';

import {
    Link,
    Button,
} from 'react-admin';


 const styles = theme => ({


    sequenceRoot: {
      margin: '15px 0px',
      zIndex: 2,
    },
    cardsContainer: {
      display: "flex",
      "flex-wrap": "nowrap",
      "overflow-x": "auto",
      "-webkit-overflow-scrolling": "touch",
      "-ms-overflow-style": "-ms-autohiding-scrollbar",
      //"max-width": "70em"
    },
    card: {
       "flex": "0 0 auto",
//      "width": "30%",
      margin: "15px 15px 15px 1px",
      maxWidth: "400px"
    },
    cardContent: {
    },
    video:{
        border: 0,
        top: 0,
        left: 0,
        width: '100%' ,
        height: '100%'
    },
     videopreview:{
         width: 'auto' ,
         height: '28em',
         padding: '0px 0 20px 20px',
     },
    media: {
        height: '18em',
        "object-fit": "cover",
        maxHeight: "50vh",
    },

  actions: {
    display: 'flex',
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: "red[500]",
  },

 cardPreview: {
   // maxWidth: '60em'
 }
});


export class Sequence extends Component {
  constructor(props) {
      //  // console.log(props.classes.cardsContainer);
      const styleTag = document.createElement("style");
      const cssTemplateString = [ '',
                                  '::-webkit-scrollbar { -webkit-appearance: none; width: 17px;}',
                                  '::-webkit-scrollbar-thumb{border-radius:4px;background-color:rgba(43, 152, 240,.5);-webkit-box-shadow:0 0 1px rgba(255,255,255,.5)}',
                                ].join(' .'+props.classes.cardsContainer || '');
      styleTag.innerText = cssTemplateString;
      window.document.head.insertAdjacentElement('beforeend', styleTag);
      super(props);
  }


  render() {

    const {
         classes,
         ids,
         basePath,
         data,
         props
      } = this.props;

    let preview = ( <VideoPreview {...props} ></VideoPreview> );
    let sequences = (
      ids.map((itemRecord, index) => {
      let item = data[itemRecord];

      return (

          <Card className={classes.card} key={index}>
           <div className={classes.cardContent} >
            <CardHeader
              title={item.title}
              subheader={['Sequence',index+1].join(' ')}
              avatar={
                        <React.Fragment>
                            <SlideshowIcon />
                            <Avatar aria-label="Sequence" className={classes.avatar}  src={item.image}>
                            </Avatar>
                        </React.Fragment>
                      }
              action={
                        <React.Fragment>
                            <Button
                                  component={Link}
                                  to={{
                                  pathname: [basePath, props.id].join('/'),
                                  search: `seq=`+index,
                                  }}

                                  label='Edit'
                              >
                                <ContentCreateIcon />
                              </Button>
                          </React.Fragment>
                      }
             />

              <CardMedia
                component={(item.video  && item.video._provider ==="youtube") ? "iframe" : "img"}
                height="140"
                className={classes.media}
                src={(item.video && item.video._provider ==="youtube") ? item.video._src + "?rel=0&autoplay=0&mute=1&controls=0&loop=1" : item.image}
                title={item.title}
              />

              <CardContent className={classes.content}>
                  <Typography variant="h5" component="h2">
                      {item.title}
                  </Typography>
                  <Typography component="p">
                      {item.text}
                  </Typography>
              </CardContent>
              <CardActions style={{ justifyContent: 'flex-end' }}>
                <Button
                    component={Link}
                    to={{
                    pathname: [basePath, props.id].join('/'),
                    search: (index > 0) ? `seq=`+index : '',
                    }}

                    label='Edit'
                >
                  <ContentCreateIcon />
                </Button>
              </CardActions>
            </div>
          </Card>
        )
    })
   );
   let res =  <div className={classes.root} >
                {preview}
                <div className={classes.sequenceRoot} >
                  <div className={classes.cardsContainer} >{sequences}</div>
                </div>
              </div>
              ;

   return res;

  }

}



Sequence.propTypes = {
    basePath: PropTypes.string,
    children: PropTypes.node,
    classes: PropTypes.object,
    className: PropTypes.string,
    fields: PropTypes.object,
    meta: PropTypes.object,
    record: PropTypes.object,
    resource: PropTypes.string,
    translate: PropTypes.func,
};

export default compose(translate, withStyles(styles))(Sequence);

