import auth0 from 'auth0-js';
import Cookies from 'js-cookie';

class Auth {
    constructor() {
        this.auth0 = new auth0.WebAuth({
            // the following three lines MUST be updated
            domain: process.env.REACT_APP_AUTH0_DOMAIN,
            audience: 'https://'+process.env.REACT_APP_AUTH0_DOMAIN+'/userinfo',
            clientID: process.env.REACT_APP_AUTH0_CLIEND_ID,
            redirectUri: process.env.REACT_APP_AUTH0_CALLBACK,
            responseType: 'id_token token',
            scope: 'openid profile email offline_access'
        });

        this.getProfile = this.getProfile.bind(this);
        this.isAuthenticated = this.isAuthenticated.bind(this);
        this.signIn = this.signIn.bind(this);
        this.signOut = this.signOut.bind(this);
    }

    getProfile() {
        this.profile = (this.profile) ? this.profile : JSON.parse(localStorage.getItem('user'));
        return this.profile;
    }
    getRole() {
      function intersect(a, b) {
        return [...new Set(a)].filter(x => new Set(b).has(x));
      }
      var rightRoles = intersect(Object.keys(window.credentials),['superAdmin','admin','premium','regular','pending']);
      return intersect(this.getProfile()['roles'], rightRoles).pop();
    }
    
    getCompany(){
        var profile = this.getProfile();
        return profile['company']['company_name'];
    }

    getCompanySlug(){
        var profile = this.getProfile();
        return profile['company']['company_slug'];
    }

    getRoles() {
        var profile = this.getProfile();
        return profile['roles'];
    }
    setSession(authResult){
      this.idToken = authResult.idToken;
      this.profile = authResult.idTokenPayload;

      // set the time that the id token will expire at (auth0 exp is in UNIX need to * 1000)
      let expiresAt = authResult.idTokenPayload.exp * 1000 + authResult.expiresIn;
      expiresAt = new Date(expiresAt);

      // set cookies
      Cookies.set('token', this.idToken, { expires: expiresAt });
      this.profile['roles'] = (this.profile["https://roles"]) ? this.profile["https://roles"]["roles"] : ['pending'];
      this.profile['company'] = (this.profile["https://company"]) ? this.profile["https://company"] : {company_name:'none', company_slug:'none'};

      delete(this.profile["https://roles"]);
      delete(this.profile["https://company"]);
      localStorage.setItem('user', (JSON.stringify(this.profile)).replace("https://roles","roles"));
      const role = this.getRole();
      const roles = this.profile['roles'];
      localStorage.setItem('role', role);
      const permissions = [...new Set(roles.reduce(function(acc, r) {
          return [...acc, ...window.credentials[r]];
      }, []))];
      localStorage.setItem('permission', permissions.join(','));
    }
    getIdToken() {
        return this.idToken;
    }

    isAuthenticated() {
        return Cookies.get('token');
    }

    signIn() {
        this.auth0.authorize();
    }

    signOut() {
        if (Cookies.get('token')) {
            this.idToken = null;
            this.profile = null;
            localStorage.removeItem('user');
            localStorage.removeItem('role');
            localStorage.removeItem('permission');
            Cookies.remove('token');
            this.auth0.logout({
                clientID: process.env.REACT_APP_AUTH0_CLIEND_ID,
                returnTo: process.env.REACT_APP_AUTH0_REDIRECT_ON_LOGOUT
            });
        }

    }

    checkSession(cb) {
        // auth for the app
        this.auth0.checkSession({}, (err, authResult) => {
            if (err) {
                console.error('err', err);
                //we login when error
                this.signIn();
                return;
            }

            const { user } = authResult.idTokenPayload;
            //Check roles for the pending validation users
            const roles = authResult.idTokenPayload["https://roles"]["roles"] || [];
            //We launch the callback again (sent to authParovider.js with the right payload needed)
            cb({
                'roles' : roles,
                token: authResult.accessToken,
                expiresIn: authResult.expiresIn,
                user: user,
                authResult : authResult
            });
        });
    }
}

const auth0Client = new Auth();

export default auth0Client;