import React, { Component } from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import { translate } from 'ra-core';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';

import DraggableFormInput from './DraggableFormInput';

const sanitizeProps = ({ classes, ...props }) => props;

const styles = theme => ({
    root: {
        padding: 0,
        marginBottom: 0,
        '& > li:last-child': {
            borderBottom: 'none',
        },
    },
    line: {
        display: 'flex',
        listStyleType: 'none',
        borderBottom: `solid 1px ${theme.palette.divider}`,
        [theme.breakpoints.down('xs')]: { display: 'block' },
        '&.fade-enter': {
            opacity: 0.01,
            transform: 'translateX(100vw)',
        },
        '&.fade-enter-active': {
            opacity: 1,
            transform: 'translateX(0)',
            transition: 'all 500ms ease-in',
        },
        '&.fade-exit': {
            opacity: 1,
            transform: 'translateX(0)',
        },
        '&.fade-exit-active': {
            opacity: 0.01,
            transform: 'translateX(100vw)',
            transition: 'all 500ms ease-in',
        },
    },
    cardsContainer: {
        // display: "inline-block",
        // minWidth : "100%",
        // //height: "50px",
        // margin: "5px 0",
        // padding: "0"
         display: "flex",
        flexWrap: "nowrap",
        overflowX: "auto",
    },

    /* cardsContainer: {
      display: "flex",
      "flex-flow": "row wrap",
    },*/
    action: {
        paddingTop: '0.5em',
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
});

export class OrderedFormIterator extends Component {
    constructor(props) {
        super(props);
        // we need a unique id for each field for a proper enter/exit animation
        // but redux-form doesn't provide one (cf https://github.com/erikras/redux-form/issues/2735)
        // so we keep an internal map between the field position and an autoincrement id
        this.nextId = 0;
        this.ids = props.fields ? props.fields.map(() => ('draggableId-'+this.nextId++)) : [];
    }

    getListStyle = (snapshot, draggableStyle) => {
        /*  if (snapshot.isDraggingOver) {
                    return '#rgb(227, 255, 224, 0.7)';
                  }

                  // If it is the home list but not dragging over
                  if (snapshot.draggingFromThisWith) {
                    return '#rgb(227, 255, 224, 0.7)';
                  }*/
    return {
      padding: snapshot.isDraggingOver ? "15px 15px 25px 15px" : "0px",
      margin:  snapshot.isDraggingOver ? "0px" : "15px 0px" ,
      // change background colour if dragging
      background: snapshot.isDraggingOver ? "rgb(173, 185, 247, 0.7)" : "#e8e8e8",
      border: snapshot.isDraggingOver ? "1px solid rgb(173, 185, 247, 0.7)" : "none",
      "listStyle": "none",


      // styles we need to apply on draggables
      ...draggableStyle
      }
    };

    removeField = index => () => {
        const { fields } = this.props;
        this.ids.splice(index, 1);
        fields.remove(index);
    };

    addField = () => {
        const { fields } = this.props;
        this.ids.push(this.nextId++);
        fields.push({});
    };

    onDragEnd = result => {
        if (!result.destination) {
            return;
        }
        const { fields } = this.props;
        const startIndex = result.source.index;
        const endIndex = result.destination.index;
        const [removed] = this.ids.splice(startIndex, 1);
        this.ids.splice(endIndex, 0, removed);
        fields.move(startIndex, endIndex);
    };

    onDragStart= elt => {
        console.log("onDragStart", this, elt);
    };

    render() {

        const {
            classes = {},
            fields,
            data,
            translate,
        } = this.props;

        return fields ? (

         <DragDropContext 
         onDragEnd={this.onDragEnd}
         onDragStart={this.onDragStart}
         >
            <Droppable droppableId="droppable" direction="horizontal">
              {(provided, snapshot:DroppableStateSnapshot) => {
                
                return (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  style={this.getListStyle(snapshot,provided.droppableProps.style)}
                  className={classes.cardsContainer}
                >
                    {fields.map((member, index) => {
                          // console.log("fields", fields, member, index);
                        let item = fields.value[index];
                        item.index = index;
                        item.member = member;
                        const draggableId = "draggableId-" + this.ids[index];
                        return (
                            <DraggableFormInput
                                id={`${draggableId}`}
                                index={index}
                                member={member}
                                key={member}
                                onRemove={this.removeField}
                                item={item}
                                data={data}
                                {...sanitizeProps(this.props)}
                            />
                        );
                    }
                    )}
                    {provided.placeholder}
                    <div className={classes.line}>
                        <span className={classes.action}>
                            <Button
                                size="small"
                                onClick={this.addField}
                            >
                                <AddIcon className={classes.leftIcon} />
                                {translate('ra.action.add')}
                            </Button>
                        </span>
                    </div>
                </div>
                );
            }
            }
            </Droppable>
          </DragDropContext>

        ) : null;
    }
}

OrderedFormIterator.propTypes = {
    basePath: PropTypes.string,
    children: PropTypes.node,
    classes: PropTypes.object,
    className: PropTypes.string,
    fields: PropTypes.object,
        key: PropTypes.string,
    meta: PropTypes.object,
    record: PropTypes.object,
    resource: PropTypes.string,
    translate: PropTypes.func,
};

export default compose(translate, withStyles(styles))(OrderedFormIterator);
