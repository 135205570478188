import React, { Component } from 'react';
import compose from 'recompose/compose';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { translate } from 'react-admin';
import SiteIcon from '@material-ui/icons/Web';
import VideoIcon from '@material-ui/icons/Theaters';
import auth0Client from '../components/Auth/Auth'
const styles = {
    media: {
        height: '18em',
    },
};

const mediaUrl = "https://source.unsplash.com/category/nature/";

class Welcome extends Component {

constructor(props){
    super(props);
    this.state = {user :auth0Client.getProfile()};
}

componentDidMount() {
    this.setState({user : auth0Client.getProfile()});
}

render() {

const{ classes } = this.props;
const user = this.state.user;
    return (user ? (
    <Card>
        <CardMedia image={mediaUrl} className={classes.media} />
        <CardContent>
            <Typography variant="subtitle2" component="h2">
                {"Welcome "+ (user && user.name)? user.name : ''}
            </Typography>
            <Typography component="p">
                {(user && user.company)? user.company.company_name : ''} - {(user && user.email)? user.email : ''}
            </Typography>
        </CardContent>
        <CardActions style={{ justifyContent: 'flex-end' }}>
            <Button href="/sites">
                <SiteIcon style={{ paddingRight: '0.25em' }} />
                Manage Your Sites
            </Button>
            <Button href="/pages">
                <VideoIcon style={{ paddingRight: '0.25em' }} />
                Manage Your Pages and Generate Videos
            </Button>
        </CardActions>
    </Card>
) : (''));
}
}

const enhance = compose(
    withStyles(styles),
    translate
);

export default enhance(Welcome);
