import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import compose from 'recompose/compose';
import {translate} from 'ra-core';

import Card from '@material-ui/core/Card';
import Select from '@material-ui/core/Select';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import MenuItem from '@material-ui/core/MenuItem';


const styles = theme => ({
    video: {
        border: 0,
        top: 0,
        left: 0,
        "object-fit": "cover",
        width: '100%',
        height: '100%'
    },
    videopreview: {
        width: 'auto',
        height: '45vh',
        "object-fit": "cover",
        padding: '0px 0 20px 20px',
        margin: 'auto',
    },
    cardPreview: {},
    selectPreview: {
        padding: '5px',
        textTransform: 'uppercase',
        margin: '0',
        height: '2em'
    },
    root: {
        "&.sticky": {
            position: "fixed",
            right: "25px",
            bottom: "50px",
            maxWidth: "calc(30% - 50px)",
        },
        [theme.breakpoints.down('md')]: {
            position: "relative",
            right: 0,
            maxWidth: "100%"
        }
    },
    chip: {
        marginRight: theme.spacing(1),
    },
    section1: {
        margin: `${theme.spacing(3)}px ${theme.spacing(2)}px`,
    },
    section2: {
        margin: theme.spacing(2),
    },
    section3: {
        margin: `${theme.spacing(6)}px ${theme.spacing(2)}px ${theme.spacing(2)}px`,
    },
    buttonProgress: {
        color: 'green',
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    barProgress: {
        marginTop: 12
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    colorSecondary: {
        backgroundColor: 'red'
    }
});


export class VideoPreview extends Component {


    constructor(props) {
        super(props);
        let template = this.props.input ? this.props.input.value.template : 'template1';
        this.state = {
            previewTemplate: template,
            previewTemplates: [],
            ts: new Date().getTime()
        };
        //  // console.log('Constructor', props, this.state);
    }

    async componentDidMount() {
        const id = this.props.idPage;
        let response = await fetch(process.env.REACT_APP_DATA_PROVIDER + `/videos/list/previews/${id}`);
        let responseData = await response.json();
        let previewTemplates = responseData.data || [];
        let previewTemplate = (this.props.input && this.props.input.value) ? this.props.input.value.template : '';
        this.setState({
            loading: false,
            "previewTemplate": previewTemplate,
            "previewTemplates": previewTemplates,
            'ts': new Date().getTime()
        });
        //  // console.log('didMount', this.props, this.state, responseData);
    }


    previewChange = event => {
        let previewTemplate = event.target.value;
        //  // console.log(event, this.state);
        this.setState({'previewTemplate': previewTemplate, 'ts': new Date().getTime()});
        if(this.props.input){
            this.props.input.onChange({
                'template': previewTemplate,
                'video_id': this.props.idPage,
                'file': "/videos/" + this.props.idPage + "/" + previewTemplate + ".mkv"
            });
        }
        this.props.videoStatus(previewTemplate);
    };

    getVideoStreamUrl = (previewTemplate) =>{
        let ts1 = (this.state.ts) ? this.state.ts : 0;
        let ts2 = (this.props.ts) ? this.props.ts : 0;
        let ts = (ts1 > ts2) ? ts1 : ts2;
        let url = '';
        //console.log('getVideoStream', ts1,ts2,ts);
        
        if(process.env.REACT_APP_VIDEO_SOURCE === "s3") {
            url = process.env.REACT_APP_VIDEO_S3_URL_BASE+`/${this.props.idPage}/${previewTemplate}.mkv?${ts}`; //"/" + this.props.idPage + "/" + previewTemplate + ".mkv?" + this.state.ts;
        } else {
            url = process.env.REACT_APP_VIDEO_STREAM_URL_BASE+`/videos/stream/${this.props.idPage}/${previewTemplate}?${ts}`; //"/videos/" + this.props.idPage + "/" + previewTemplate + ".mkv?" + this.state.ts;
        }
       
       return url;
    }

    render() {
        //  // console.log('Render Video Preview ', this.props, this.state);
        const {
            classes
        } = this.props;

        let {previewTemplates, previewTemplate} = this.state;
        if (!previewTemplate) {
            previewTemplate = (this.props.input && this.props.input.value)  ? this.props.input.value.template : 'template1';
        }
        const SelectPreview = () => (
            <Select
                className={classes.selectPreview}
                value={previewTemplate}
                onChange={this.previewChange}
                inputProps={{
                    name: 'previewTemplate',
                    id: 'previewTemplate',
                }}
            >
                {previewTemplates.map((item) => {
                    return (<MenuItem value={item.id} key={"MenuItem_" + item.id}>{item.name}</MenuItem>)
                })}

            </Select>
        );

        return (
            <Card className={classes.cardPreview}>
                <CardHeader title="Video Preview" subheader="Generated Video from the sequences ..." action={
                    <SelectPreview/>
                }/>
                <CardMedia
                    component="video"
                    height="140"
                    className={classes.videopreview}
                    src={this.getVideoStreamUrl(previewTemplate)}
                    type="video/mkv"
                    title="Generated Video"
                    controls={true}
                />
            </Card>
        );
    }
}

VideoPreview.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default compose(translate, withStyles(styles))(VideoPreview);
